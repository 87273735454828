import React from 'react';
import PropTypes from 'prop-types';

const PageContent = ({ children }) => {
  return (
    <div className="page-layout__content">
      <section className="content">
        <div className="content__container">{children}</div>
      </section>
    </div>
  );
};

export default PageContent;

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
