import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Zendesk, { ZendeskAPI } from "../ZendexConfig";

const thisYear = moment().format('YYYY');

const FirstPage = () => {
  const { t, i18n } = useTranslation();
  let currentLang = i18n.language;  
  const ZENDESK_KEY = "ea40a5ed-5f3f-42be-8b8b-beee54b3ba63"

  useEffect(() => {
    return () => {
      ZendeskAPI("messenger", "hide");
    }
  }, [])
  
  return (
    <div className="page__inner">
      {currentLang === 'en' && (
        <Zendesk defer zendeskKey={ZENDESK_KEY} />
      )}
      <div className="page__content p-0">
        <div className="main-page">
          <div className="main-page__logo">
            <div className="container">
              <img src={require('../img/main-logo.png')} alt="Liketo me" />
            </div>
          </div>
          <Carousel
            interval={5000}
            fade
            controls={false}
            indicators={false}
            pause={false}
          >
            <Carousel.Item className="is-happy">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="main-slide">
                      <h2 className="main-slide__title">
                        {t('FirstPage.yourClients')}
                        <span>{t('FirstPage.happy')}</span>
                      </h2>
                      <p className="main-slide__text">{t('FirstPage.findWhy')}</p>
                      <div className='main-slide-btn-container'>
                        <p className="main-slide__btn">
                          <Link className="btn" to="/login">
                            {t('FirstPage.start')}
                          </Link>
                        </p>
                        <p className="main-slide__btn">
                          <button className="btn find-more-button">
                            {t('FirstPage.findMore')}
                          </button>
                        </p>
                      </div>
                      <img
                        className="main-slide__img-xs"
                        src={require('../img/slide-xs-1.png')}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="is-indifferent">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="main-slide">
                      <h2 className="main-slide__title">
                        {t('FirstPage.yourClients')}
                        <span>{t('FirstPage.indifferent')}</span>
                      </h2>
                      <p className="main-slide__text">{t('FirstPage.findWhy')}</p>
                      <div className='main-slide-btn-container'>
                        <p className="main-slide__btn">
                          <Link className="btn" to="/login">
                            {t('FirstPage.start')}
                          </Link>
                        </p>
                        <p className="main-slide__btn">
                          <button className="btn find-more-button">
                            {t('FirstPage.findMore')}
                          </button>
                        </p>
                      </div>
                      <img
                        className="main-slide__img-xs"
                        src={require('../img/slide-xs-2.png')}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="is-angry">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="main-slide">
                      <h2 className="main-slide__title">
                        {t('FirstPage.yourClients')}
                        <span>{t('FirstPage.unHappy')}</span>
                      </h2>
                      <p className="main-slide__text">{t('FirstPage.findWhy')}</p>
                      <div className='main-slide-btn-container'>
                        <p className="main-slide__btn">
                          <Link className="btn" to="/login">
                            {t('FirstPage.start')}
                          </Link>
                        </p>
                        <p className="main-slide__btn">
                          <button className="btn find-more-button">
                            {t('FirstPage.findMore')}
                          </button>
                        </p>
                      </div>
                      <img
                        className="main-slide__img-xs"
                        src={require('../img/slide-xs-3.png')}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="page__footer-wrapper">
        <div className="main-page-footer">
          <div className="container">
            <p className="has-logo">{`${t('FirstPage.copyright')} © ${thisYear} liketo.me`}</p>
            {/* {t('FirstPage.phone') && 
              <p>
                {t('FirstPage.phone')}:
                <a href="tel:+380442002305"> +38 044 200 23 05</a>
              </p>
            } */}
            <p>
              <Link to="/policy" target="_blank" rel="noopener noreferrer">
              {t('FirstPage.policy')}
              </Link>
            </p>
            <p>
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
              {t('FirstPage.terms')}
              </Link>
            </p>
            <p>
              <a href="mailto:mail.liketome@gmail.com">
                mail.liketome@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
