import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import IndicatorPercent from '../Statistic/IndicatorPercent';
import ChartLine from '../Charts/ChartLine';
import { numberToFixed, lineDataFunc } from '../../usefulFunctions';

const RatingRowNps = ({
  item,
  index,
  pollsOrAddress,
  showGraphic,
  currentPage,
  trunc
}) => {
  const { t } = useTranslation();

  const like =
    Math.round(((item.current.like * 100) / item.current.total) * 100) / 100;

  const neutral =
    Math.round(((item.current.neutral * 100) / item.current.total) * 100) / 100;

  const unlike =
    Math.round(((item.current.unlike * 100) / item.current.total) * 100) / 100;

  const lineData = lineDataFunc(
    item.current.nps_range,
    item.current.date_range,
    trunc
  );

  const otherRanges = {
    like: item.current.like_range,
    neutral: item.current.neutral_range,
    unlike: item.current.unlike_range
  };

  return (
    <div className="rating-panel__body">
      <div className="col-3">{item.title || ''}</div>
      <div className="col-2">
        <div className="indicators">
          <p className="indicators__score">
            {item.current.total !== null ? item.current.total : ''}
          </p>
          {item.current.total !== null && item.prev.total !== null ? (
            <IndicatorPercent
              currentNum={item.current.total}
              prevNum={item.prev.total}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="col-2">
        <div className="indicators">
          <div className="indicators__progress">
            {!(isNaN(like) && isNaN(neutral) && isNaN(unlike)) ? (
              <div className="progress-chart">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {`${t('ratingPage.notHappy')}: ${item.current.unlike}`} |{' '}
                      {`${unlike}%`}
                    </Tooltip>
                  }
                >
                  <div
                    className="progress-chart__item"
                    style={{ width: `${unlike}%` }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {`${t('ratingPage.neutral')}: ${
                        item.current.neutral
                      } | ${neutral}%`}
                    </Tooltip>
                  }
                >
                  <div
                    className="progress-chart__item"
                    style={{ width: `${neutral}%` }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>{`${t('ratingPage.happy')}: ${
                      item.current.like
                    } | ${like}%`}</Tooltip>
                  }
                >
                  <div
                    className="progress-chart__item"
                    style={{ width: `${like}%` }}
                  />
                </OverlayTrigger>
              </div>
            ) : (
              <div className="progress-chart">
                <div
                  className="progress-chart__item"
                  style={{ width: '100%', background: '#a8b3c2' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="indicators">
          <div className="indicators__chart">
            <ChartLine
              lineData={lineData}
              lineColor="#F4780F"
              showGraphic={() => showGraphic(lineData, 'NPS', otherRanges)}
            />
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="indicators">
          <p className="indicators__score">
            {numberToFixed(item.current.nps, 2) || 0}
          </p>
          {item.current.nps && item.prev.nps && (
            <IndicatorPercent
              currentNum={item.current.nps}
              prevNum={item.prev.nps}
              npsOrCsat
            />
          )}
        </div>
      </div>
      <div className="col-1">
        <div className="indicators">
          <p className="indicators__score">
            {index + 1 + currentPage * 10 - 10}
          </p>
          {/* <IndicatorPercent number={0} /> */}
        </div>
      </div>
    </div>
  );
};

export default RatingRowNps;

RatingRowNps.propTypes = {
  item: PropTypes.shape({}),
  index: PropTypes.number,
  pollsOrAddress: PropTypes.string,
  showGraphic: PropTypes.func,
  currentPage: PropTypes.number,
  trunc: PropTypes.string
};

RatingRowNps.defaultProps = {
  item: null,
  index: null,
  pollsOrAddress: '',
  showGraphic: null,
  currentPage: null,
  trunc: ''
};
