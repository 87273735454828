import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const ChartLine = ({ lineData, lineColor, showGraphic }) => {
  const data = {
    labels: lineData.map((item) => item.date),
    datasets: [
      {
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        fill: false,
        lineTension: 0.1,
        legend: false,
        borderColor: lineColor,
        pointRadius: 0,
        data: lineData.map((item) => item.data)
      }
    ]
  };
  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [
        {
          display: false,
          scaleLabel: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: '#485766',
            lineHeight: 2
          },
          ticks: {
            fontColor: '#485766',
            padding: 10
          }
        }
      ],
      yAxes: [
        {
          display: false,
          scaleLabel: {
            padding: 10,
            fontStyle: 'bold',
            fontColor: '#485766',
            lineHeight: 2
          }
        }
      ]
    }
  };

  return (
    <Line
      height={75}
      data={data}
      options={options}
      onElementsClick={showGraphic}
    />
  );
};

export default ChartLine;

ChartLine.propTypes = {
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      data: PropTypes.number
    })
  ),
  lineColor: PropTypes.string,
  showGraphic: PropTypes.func
};

ChartLine.defaultProps = {
  lineData: null,
  lineColor: '',
  showGraphic: null
};
