import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import UserMenuPopUp from '../UserMenuPopUp/UserMenuPopUp';
import logo from '../../img/logo-sm.png';

const Header = () => {
  const { stateGlobal } = useContext(GlobalContext);
  const { user } = stateGlobal;

  return (
    <header className="page-header" role="banner">
      <div className="page-header__container d-flex align-items-center">
        <div className="col">
          <Link to="/" className="logo">
            <img src={logo} alt="Liketo" width="41" height="41" />
          </Link>
        </div>
        <div className="col d-none d-sm-flex justify-content-center">
          <div className="logo-customer">
            <span className="logo-customer__text">
              {user.brand_name || 'no company name'}
            </span>
          </div>
        </div>
        <UserMenuPopUp />
      </div>
    </header>
  );
};

export default Header;
