import { useState } from 'react';

const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);

  const maxPage = Math.ceil(data / itemsPerPage);

  const next = () => {
    setCurrentPage(() => Math.min(currentPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage(() => Math.max(currentPage - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return { next, prev, jump, currentPage, setCurrentPage, maxPage };
};

export default usePagination;
