import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ServiceAPI from '../../services';
import { loginEmail, loginError } from '../../actions';
import reducer, { initialState } from '../../reducers/login';
import { LoginPagesContainer } from '../StyleComponents';
import MainButton from '../Buttons/MainButton';
import { ReactComponent as IconEmail } from '../../img/sprite/svg/email.svg';

const ForgotPassword = ({ validateEmailPattern }) => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [success, setSuccess] = useState(false);

  const validateEmailField = (value) => {
    dispatch(
      loginError({
        requiredEmail: !value,
        emailPattern: !validateEmailPattern(value)
      })
    );
  };

  const validateFields = () => {
    validateEmailField(state.email.value);
    return (
      !(state.errors.requiredEmail || state.errors.emailPattern) &&
      state.email.value
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e.target.value));
    dispatch(
      loginError({
        emailNotFound: false
      })
    );
  };

  const handleSubmit = () => {
    if (!validateFields()) return false;
    service.resetUserPassword({ email: state.email.value }).then((result) => {
      if (result.status === 200) {
        setSuccess(true);
      } else {
        dispatch(
          loginError({
            emailNotFound: true
          })
        );
      }
    });
  };

  const forgotPasswordContainer = !success ? (
    <>
      <h1 className="login-form__title">{t('LoginPage.forgotPassword')}</h1>
      <p className="login-form__help-text mb-4">
        {t('LoginPage.enterYourEmail')}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form-group mb-5">
          <label htmlFor="restore-email">
            <span className="required-label">*</span>
            Email
          </label>
          <div className="form-control-wrapper">
            <input
              autoComplete="off"
              className={
                state.email.touched &&
                !state.errors.requiredEmail &&
                !state.errors.emailPattern &&
                !state.errors.emailNotFound
                  ? 'form-control form-control-email is-valid'
                  : !state.email.touched &&
                    !state.errors.requiredEmail &&
                    !state.errors.emailPattern &&
                    !state.errors.emailNotFound
                  ? 'form-control form-control-email'
                  : 'form-control form-control-email is-invalid'
              }
              id="restore-email"
              type="text"
              name="restore-email"
              onChange={(e) => {
                handlerOnChangeField(e, loginEmail);
                validateEmailField(e.target.value);
              }}
              value={state.email.value}
            />
            <IconEmail className="svg-sprite-icon icon-email" />
          </div>
          {state.errors.requiredEmail && (
            <div className="error">
              <span>{t('ERROR_TEXT.requiredEmail')}</span>
            </div>
          )}
          {state.errors.emailPattern && !state.errors.requiredEmail && (
            <div className="error">
              <span>{t('ERROR_TEXT.validEmail')}</span>
            </div>
          )}
          {state.errors.emailNotFound && (
            <div className="error">
              <span>{t('ERROR_TEXT.emailNotFound')}</span>
            </div>
          )}
        </div>
        <div className="form-group mb-0">
          <MainButton
            type="submit"
            className="btn btn-confirm d-block w-100"
            text={t('LoginPage.resetPassword')}
          />
        </div>
      </form>
    </>
    ) : (
      <p className="login-form__help-text mb-4">
        {`${t('LoginPage.linkToChangePassword')} ${state.email.value}`}
      </p>
    )

  return (
    <LoginPagesContainer
      child1={forgotPasswordContainer}
    />
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  validateEmailPattern: PropTypes.func
};

ForgotPassword.defaultProps = {
  validateEmailPattern: null
};
