export const LOGIN = 'LOGIN';
export const LOGIN_EMAIL = 'LOGIN_EMAIL';
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD';
export const LOGIN_REMEMBER_ME = 'LOGIN_REMEMBER_ME';
export const LOGIN_ERRORS = 'LOGIN_ERRORS';
export const LOGOUT = 'LOGOUT';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const EMAIL = 'EMAIL';
export const BRAND_NAME = 'BRAND_NAME';
export const OLD_PASSWORD = 'OLD_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const REPEAT_NEW_PASSWORD = 'REPEAT_NEW_PASSWORD';
export const ERROR = 'ERROR';
export const CHANGE_DEVICE_INFO = 'CHANGE_DEVICE_INFO';
export const DEVICE_NAME = 'DEVICE_NAME';
export const DEVICE_ID = 'DEVICE_ID';
export const DEVICE_ADDRESS = 'DEVICE_ADDRESS';
export const DEVICE_ERROR = 'DEVICE_ERROR';
export const POLL_NAME = 'POLL_NAME';
export const QUESTION_NAME = 'QUESTION_NAME';
export const DEVICES = 'DEVICES';
export const INITIAL_DEVICES = 'INITIAL_DEVICES';
export const POLLS_ERRORS = 'POLLS_ERRORS';
export const POLL_TOUCHED = 'POLL_TOUCHED';
export const POLL_EDIT = 'POLL_EDIT';
export const REPORT_NAME = 'REPORT_NAME';
export const REPORT_POLL = 'REPORT_POLL';
export const REPORT_EMAILS = 'REPORT_EMAILS';
export const REPORT_ERRORS = 'REPORT_ERRORS';
export const REPORT_TOUCHED = 'REPORT_TOUCHED';
export const REPORT_EDIT = 'REPORT_EDIT';
export const LOADING = 'LOADING';
export const HELP_DESK = 'HELP_DESK';
export const CHANGE_HELP_TEXT = 'CHANGE_HELP_TEXT';
export const HELP_DESK_ERROR = 'HELP_DESK_ERROR';
export const HELP_DESK_DEFAULT = 'HELP_DESK_DEFAULT';
