import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'react-lodash';
import { pagination } from '../../usefulFunctions';
import { ReactComponent as IconMoveToNext } from '../../img/sprite/svg/move-to-next.svg';

const PaginationBox = ({
  currentPage,
  maxPage,
  next,
  prev,
  jump,
  changePage
}) => {
  if (maxPage === 1) {
    return (
      <ul className="pagination">
        <li className="page-item" />
      </ul>
    );
  }
  if (maxPage < 10) {
    return (
      <ul className="pagination">
        <Map
          collection={Array.from(Array(maxPage), (_, i) => i + 1)}
          iteratee={(item, index) => {
            return (
              <li className="page-item" key={index}>
                <a
                  className={
                    item === currentPage ? 'page-link is-active' : 'page-link'
                  }
                  role="button"
                  onClick={() => {
                    changePage(item);
                    jump(item);
                  }}
                  //   href="#"
                >
                  {item}
                </a>
              </li>
            );
          }}
        />
      </ul>
    );
  }
  if (maxPage > 10) {
    const paginationBoxes = pagination(currentPage, maxPage);
    return (
      <ul className="pagination">
        <li className="page-item">
          <a
            className={
              currentPage === 1
                ? 'page-link page-link-next is-disabled'
                : 'page-link page-link-next'
            }
            onClick={() => {
              prev();
              changePage(currentPage - 1);
            }}
            // href="#"
            aria-label="Previous"
          >
            <IconMoveToNext className="svg-sprite-icon icon-move-to-next" />
          </a>
        </li>
        <Map
          collection={paginationBoxes}
          iteratee={(item) => {
            if (item === '...') {
              return (
                <li className="page-item">
                  <a
                    className="page-link is-disabled"
                    // href="#"
                  >
                    ...
                  </a>
                </li>
              );
            }
            return (
              <li className="page-item">
                <a
                  className={
                    item === currentPage ? 'page-link is-active' : 'page-link'
                  }
                  onClick={() => {
                    jump(item);
                    changePage(item);
                  }}
                  //   href="#"
                >
                  {item}
                </a>
              </li>
            );
          }}
        />
        <li className="page-item">
          <a
            className={
              currentPage === maxPage
                ? 'page-link page-link-next is-disabled'
                : 'page-link page-link-next'
            }
            onClick={() => {
              next();
              changePage(currentPage + 1);
            }}
            // href="#"
            aria-label="Next"
          >
            <svg className="svg-sprite-icon icon-move-to-next">
              <IconMoveToNext />
            </svg>
          </a>
        </li>
      </ul>
    );
  }
};

export default PaginationBox;

PaginationBox.propTypes = {
  currentPage: PropTypes.number,
  maxPage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  jump: PropTypes.func,
  changePage: PropTypes.func
};

PaginationBox.defaultProps = {
  currentPage: 1,
  maxPage: 1,
  next: null,
  prev: null,
  jump: null,
  changePage: null
};
