import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as IconUpArrow } from '../../img/sprite/svg/up-arrow.svg';

const diffFunc = (currentNum, prevNum, npsOrCsat) => {
  if (npsOrCsat) return Math.round(currentNum - prevNum);

  return Math.round(((currentNum - prevNum) * 100) / prevNum);
};

const IndicatorPercent = ({ currentNum, prevNum, npsOrCsat, csat }) => {
  const { t } = useTranslation();
  const number = diffFunc(currentNum, prevNum, npsOrCsat);

  if (typeof number === 'number' && !isNaN(number) && isFinite(number)) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>{`${t('statisticPage.previus')} ${prevNum}${
            csat ? '%' : ''
          }`}</Tooltip>
        }
      >
        <p className="indicators__percent">
          <span
            className={`indicators-percent ${
              number > 0 ? 'is-up' : number < 0 ? 'is-down' : ''
            }`}
          >
            {number !== 0 && (
              <IconUpArrow className="svg-sprite-icon icon-up-arrow" />
            )}
            {npsOrCsat ? Math.abs(number) : `${Math.abs(number)}%`}
          </span>
        </p>
      </OverlayTrigger>
    );
  }
  if ((!npsOrCsat && isNaN(number)) || !isFinite(number)) {
    return (
      <p className="indicators-percent not-visible">
        <span>0</span>
      </p>
    );
  }
  return '';
};

export default IndicatorPercent;

IndicatorPercent.propTypes = {
  number: PropTypes.number
};

IndicatorPercent.defaultProps = {
  number: null
};
