import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GlobalContext from '../../context/globalContext';
import { ReactComponent as IconStatistic } from '../../img/sprite/svg/statistics.svg';
import { ReactComponent as IconRating } from '../../img/sprite/svg/rating.svg';
import { ReactComponent as IconPolls } from '../../img/sprite/svg/polls.svg';
import { ReactComponent as IconDevices } from '../../img/sprite/svg/devices.svg';
import { ReactComponent as IconReports } from '../../img/sprite/svg/reports.svg';

export const LeftNavBarContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100%;
  background-color: purple;
  box-sizing: border-box;
`;

export const LeftNavBarItemStyled = styled.div``;

const LeftNavBar = () => {
  const { t } = useTranslation();
  const { stateGlobal } = useContext(GlobalContext);
  const userHasHardware = stateGlobal.user?.firm?.has_hardware;
  const userIsOwnerOrAdmin =
    stateGlobal.user?.is_owner || stateGlobal.user?.is_admin;

  return (
    <div className="page-layout__aside d-none d-xl-flex">
      <aside className="aside">
        <div className="aside__container">
          <nav className="main-nav">
            <ul className="main-nav__list">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{t('statisticPage.pageName')}</Tooltip>}
              >
                <li className="main-nav__item">
                  <NavLink
                    exact
                    to="/"
                    className="main-nav__link menu__link--statistics"
                    activeClassName="is-active"
                  >
                    <IconStatistic className="svg-sprite-icon icon-statistics" />
                  </NavLink>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{t('ratingPage.pageName')}</Tooltip>}
              >
                <li className="main-nav__item">
                  <NavLink
                    exact
                    to="/rating"
                    className="main-nav__link menu__link--rating"
                    activeClassName="is-active"
                  >
                    <IconRating className="svg-sprite-icon icon-rating" />
                  </NavLink>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{t('pollsPage.pageName')}</Tooltip>}
              >
                <li className="main-nav__item">
                  <NavLink
                    exact
                    to="/polls"
                    className="main-nav__link menu__link--polls"
                    activeClassName="is-active"
                  >
                    <IconPolls className="svg-sprite-icon icon-polls" />
                  </NavLink>
                </li>
              </OverlayTrigger>
              {userHasHardware && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{t('devicesPage.pageName')}</Tooltip>}
                >
                  <li className="main-nav__item">
                    <NavLink
                      exact
                      to="/devices"
                      className="main-nav__link menu__link--devices"
                      activeClassName="is-active"
                    >
                      <IconDevices className="svg-sprite-icon icon-devices" />
                    </NavLink>
                  </li>
                </OverlayTrigger>
              )}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{t('reportsPage.pageName')}</Tooltip>}
              >
                <li className="main-nav__item">
                  <NavLink
                    exact
                    to="/reports"
                    className="main-nav__link menu__link--reports"
                    activeClassName="is-active"
                  >
                    <IconReports className="svg-sprite-icon icon-reports" />
                  </NavLink>
                </li>
              </OverlayTrigger>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default LeftNavBar;
