import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import { ReactComponent as IconEdit } from '../../img/sprite/svg/edit.svg';
import { ReactComponent as IconAlert } from '../../img/sprite/svg/alert.svg';
import { getInfoFromToken } from '../../services';
import EditButton from '../Buttons/EditButton';

const UserRow = (props) => {
  const {
    id,
    position,
    email,
    firstName,
    lastName,
    isAdmin,
    isOwner,
    isActive
  } = props;
  const { t } = useTranslation();
  const { stateGlobal } = useContext(GlobalContext);
  const currentUser = getInfoFromToken('user_id') === id;

  const getPosition = currentUser ? 'Я' : position ? position : '';

  const canEditUser =
    currentUser || stateGlobal.user?.is_owner || (!isOwner && !isAdmin);

  return (
    <div className="users-panel__body">
      <div className="col-4 users-panel__body-user">
        {getPosition === 'Я'
          ? `${getPosition} (${email})`
          : `${getPosition} ${email}`}
      </div>
      <div className="col-3 users-panel__body-name">
        {`${firstName} ${lastName}`}
        {!isActive && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <b>{t('Users.accountNotVerified')}</b>
              </Tooltip>
            }
          >
            <span>
              <IconAlert className="svg-sprite-icon icon-alert" />
            </span>
          </OverlayTrigger>
        )}
      </div>
      <div className="col-2 users-panel__body-admin">
        <span className={isAdmin ? 'user-status is-active' : 'user-status'}>
          {isAdmin ? t('Users.yes') : t('Users.no')}
        </span>
      </div>
      <div className="col-2 users-panel__body-owner">
        <span className={isOwner ? 'user-status is-active' : 'user-status'}>
          {isOwner ? t('Users.yes') : t('Users.no')}
        </span>
      </div>
      <div className="col-1 users-panel__body-edit">
        {canEditUser && (
          <EditButton
            tooltipText={t('Users.edit')}
            linkTo={currentUser ? '/account' : `/users/${id}`}
          />
        )}
      </div>
    </div>
  );
};

export default UserRow;

UserRow.propTypes = {
  id: PropTypes.number,
  position: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
  isActive: PropTypes.bool
};

UserRow.defaultProps = {
  id: null,
  position: '',
  email: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  isOwner: false,
  isActive: false
};
