import React from 'react';
import PropTypes from 'prop-types';
import LeftNavBar from '../Navigation/LeftNavBar';

const MainContent = ({ children }) => {
  return (
    <main className="page__main-content" role="main">
      <div className="page-layout">
        <div className="page-layout__container">
          <div className="page-layout__row">
            <LeftNavBar />
            {children}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainContent;

MainContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
