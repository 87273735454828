import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const Languages = ['ukr', 'ru', 'pl', 'en'];
const langObj = {
  uk: 'ukr',
  ru: 'ru',
  pl: 'pl',
  en: 'en'
};
const defaultLanguage = 'en';

// get browser language
let language = defaultLanguage;
const userBrowserLang = navigator.language || navigator.userLanguage;
const browserLang = userBrowserLang.split('-')[0];
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const notDefaultLangInSystem = ['uk', 'ru', 'pl'];
if (notDefaultLangInSystem.includes(browserLang)) {
  // cheking if user browser is ru but timezone is uk
  if (browserLang === 'ru' && 
    (timezone === 'Europe/Kyiv' || timezone === 'Europe/Kiev')) {
    language = langObj['uk'];  
  } else language = langObj[browserLang];
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || language,
    fallbackLng: 'en',
    debug: false,
    whitelist: Languages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
