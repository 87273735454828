import React from 'react';
import PropTypes from 'prop-types';

const QrFooter = ({ developedIn }) => {
  return (
    <div className="page-qr__footer">
      <p>
        {developedIn}&nbsp;
        <a href="https://liketo.me/" target="_blank" rel="noreferrer">
          liketo.me
        </a>
      </p>
    </div>
  );
};

export default QrFooter;

QrFooter.propTypes = {
  developedIn: PropTypes.string
};

QrFooter.defaultProps = {
  developedIn: 'Developed in'
};
