import React from 'react';
import PropTypes from 'prop-types';
import { MainContent, PageInner } from '../components/StyleComponents';
import Header from '../components/Header/Header';

const DefaultInner = ({ children }) => {
  return (
    <PageInner>
      <Header />
      <MainContent>{children}</MainContent>
    </PageInner>
  );
};

export default DefaultInner;

DefaultInner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
