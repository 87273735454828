import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as IconEdit } from '../../img/sprite/svg/edit.svg';

const EditButton = ({ tooltipText, linkTo }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <Link to={linkTo} className="btn-edit">
        <IconEdit className="svg-sprite-icon icon-edit" />
      </Link>
    </OverlayTrigger>
  );
};

export default EditButton;

EditButton.propTypes = {
  tooltipText: PropTypes.string,
  linkTo: PropTypes.string
};

EditButton.defaultProps = {
  tooltipText: '',
  linkTo: ''
};
