import { useState } from 'react';
import moment from 'moment';

const useDatesForReports = () => {
  const [dates, setDates] = useState({
    from: moment(),
    to: moment()
  });
  const duration = moment.duration(dates.to.diff(dates.from));
  const days = duration.asDays() + 1;
  const [comparisonDates, setComparisonDates] = useState({
    from: moment().subtract(1, 'd'),
    to: dates.from.clone().subtract(Math.floor(days), 'd')
  });

  const datesSetter = (query) => {
    if (query === 'today') {
      setDates({
        from: moment(),
        to: moment()
      });
      setComparisonDates({
        from: moment().subtract(1, 'd'),
        to: moment().subtract(1, 'd')
      });
    } else if (query === 'yestoday') {
      setDates({
        from: moment().subtract(1, 'd'),
        to: moment().subtract(1, 'd')
      });
      setComparisonDates({
        from: moment().subtract(2, 'd'),
        to: moment().subtract(2, 'd')
      });
    } else if (query === 'last7Days') {
      setDates({
        from: moment().subtract(7, 'd'),
        to: moment()
      });
      setComparisonDates({
        from: moment().subtract(14, 'd'),
        to: moment().subtract(8, 'd')
      });
    } else if (query === 'last30Days') {
      setDates({
        from: moment().subtract(30, 'd'),
        to: moment()
      });
      setComparisonDates({
        from: moment().subtract(60, 'd'),
        to: moment().subtract(31, 'd')
      });
    } else if (query === 'lastWeek') {
      setDates({
        from: moment().subtract(7, 'd').startOf('isoWeek'),
        to: moment().subtract(7, 'd').endOf('isoWeek')
      });
      setComparisonDates({
        from: moment().subtract(14, 'd').startOf('isoWeek'),
        to: moment().subtract(14, 'd').endOf('isoWeek')
      });
    } else if (query === 'lastMonth') {
      setDates({
        from: moment().subtract(1, 'months').startOf('month'),
        to: moment().subtract(1, 'months').endOf('month')
      });
      setComparisonDates({
        from: moment().subtract(2, 'months').startOf('month'),
        to: moment().subtract(2, 'months').endOf('month')
      });
    } else {
      setDates({
        from: moment(),
        to: moment()
      });
      setComparisonDates({
        from: moment().subtract(1, 'd'),
        to: moment().subtract(1, 'd')
      });
    }
  };

  const datesAndComparisonDatesSetter = (time, from) => {
    if (from === 'datesFrom') {
      const duration = moment.duration(dates.to.diff(time));
      const days = duration.asDays() + 1;
      setDates({ ...dates, from: time });
      setComparisonDates({
        from: time.clone().subtract(Math.floor(days), 'd'),
        to: time.clone().subtract(1, 'd')
      });
    } else if (from === 'datesTo') {
      const duration = moment.duration(time.diff(dates.from));
      const days = duration.asDays() + 1;
      setDates({ ...dates, to: time });
      setComparisonDates({
        from: dates.from.clone().subtract(Math.floor(days), 'd'),
        to: dates.from.clone().subtract(1, 'd')
      });
    } else if (from === 'comparisonDatesFrom') {
      const duration = moment.duration(dates.to.diff(dates.from));
      const days = duration.asDays();
      setComparisonDates({
        from: time,
        to: time.clone().add(Math.floor(days), 'd')
      });
    } else {
      const duration = moment.duration(dates.to.diff(dates.from));
      const days = duration.asDays();
      setComparisonDates({
        from: time.clone().subtract(Math.floor(days), 'd'),
        to: time
      });
    }
  };

  return {
    dates,
    setDates,
    datesSetter,
    comparisonDates,
    setComparisonDates,
    datesAndComparisonDatesSetter
  };
};

export default useDatesForReports;
