import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditButton from '../Buttons/EditButton';

export const DeviceRowContainerStyled = styled.div`
  display: flex;
`;

export const DeviceInfoStyled = styled.div`
  border: 1px solid black;
`;

const DeviceRow = (props) => {
  const { id, name, deviceNumber, address, kind, userIsOwnerOrAdmin } = props;
  const { t } = useTranslation();

  return (
    <div className="devices-panel__body">
      <div className="col-2 devices-panel__body-title text-center">
        {name || ''}
      </div>
      <div className="col-2 devices-panel__body-id text-center">{id || ''}</div>
      <div className="col-5 devices-panel__body-location">{address || ''}</div>
      <div className="col-2 devices-panel__body-status text-center">
        {kind || ''}
      </div>
      <div className="col-1 devices-panel__body-edit text-center">
        {userIsOwnerOrAdmin && (
          <EditButton
            tooltipText={t('devicesPage.edit')}
            linkTo={`/device/${id}`}
          />
        )}
      </div>
    </div>
  );
};

export default DeviceRow;

DeviceRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  deviceNumber: PropTypes.string,
  address: PropTypes.string,
  kind: PropTypes.string,
  userIsOwnerOrAdmin: PropTypes.bool
};

DeviceRow.defaultProps = {
  id: null,
  name: '',
  deviceNumber: '',
  address: '',
  kind: '',
  userIsOwnerOrAdmin: false
};
