import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Datetime from 'react-datetime';
import GlobalContext from '../../context/globalContext';
import {
  reportName,
  reportPoll,
  reportEmails,
  reportErrors,
  reportTouched,
  reportEdit,
  loading
} from '../../actions';
import reducer, { initialState } from '../../reducers/changeReport';
import useDatesForReports from '../../hooks/useDatesForReports';
import ServiceAPI from '../../services';
import PageContent from '../StyleComponents/PageContent';
import PrevPage from '../Navigation/PrevPage';
// import 'react-multi-email/style.css';
import MainButton from '../Buttons/MainButton';
import CancelButton from '../Buttons/CancelButton';
import DeleteButton from '../Buttons/DeleteButton';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';
import { validateNameLength } from '../../usefulFunctions';
import Loading from '../Loading/Loading';
import DeleteReportModal from '../Modals/DeleteReportModal';
import DefaultInner from '../../Layouts/DefaultInner';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    border: `solid 1px ${state.selectProps.error ? '#dc3545' : '#e3e7ee'}`,
    borderRadius: '3px',
    minHeight: '40px',
    cursor: 'pointer',
    boxShadow: 'none',
    ':hover': {
      border: `solid 1px ${state.selectProps.error ? '#dc3545' : '#e3e7ee'}`,
      boxShadow: 'none'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: '3px',
    backgroundColor: '#74ade6'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '1.8',
    color: '#ffffff'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#54a5f7'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    alignItems: 'center'
  })
};

const NewOrEditReport = ({ createNewReport }) => {
  const { t, i18n } = useTranslation();
  const service = new ServiceAPI();
  const history = useHistory();
  const { reportId } = useParams();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);
  const notify = () => toast(t('ChangesSaves.changesIsSaved'));
  const deleteNotify = () => toast(t('reportsPage.deleteSuccess'));

  const period = [
    { value: 'today', label: t('reportsPage.today') },
    { value: 'yestoday', label: t('reportsPage.yesterday') },
    { value: 'last7Days', label: t('reportsPage.last7Days') },
    { value: 'lastWeek', label: t('reportsPage.lastWeek') },
    { value: 'last30Days', label: t('reportsPage.last30Days') },
    { value: 'lastMonth', label: t('reportsPage.lastMonth') },
    { value: 'userDateRange', label: t('reportsPage.userDateRange') }
  ];
  const comparisonPeriodValues = {
    today: { value: 'yestoday', label: t('reportsPage.yesterday') },
    yestoday: { value: '2DaysAgo', label: t('reportsPage.2DaysAgo') },
    last7Days: { value: '2WeeksAgo', label: t('reportsPage.2WeeksAgo') },
    lastWeek: { value: '2WeeksAgo', label: t('reportsPage.2WeeksAgo') },
    last30Days: { value: '2MonthsAgo', label: t('reportsPage.2MonthsAgo') },
    lastMonth: { value: '2MonthsAgo', label: t('reportsPage.2MonthsAgo') }
  };

  const sendingFrequencys = [
    { value: '1', label: t('reportsPage.everyDay') },
    { value: '7', label: t('reportsPage.everyWeek') },
    { value: 'month', label: t('reportsPage.everyMonth') },
    { value: 'year', label: t('reportsPage.everyYear') }
  ];
  const [state, dispatch] = useReducer(reducer, initialState);
  const [polls, setPolls] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(period[0]);
  const [comparisonPeriod, setComparisonPeriod] = useState(false);
  const [selectedSendingFrequencys, setSelectedSendingFrequencys] = useState(
    sendingFrequencys[0]
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    dates,
    setDates,
    datesSetter,
    comparisonDates,
    setComparisonDates,
    datesAndComparisonDatesSetter
  } = useDatesForReports();

  useEffect(() => {
    dispatchGlobal(loading(true));
    service.getAllShortQuizzes().then((resp) => {
      if (resp.status === 200) {
        setPolls(
          resp.data.map((item) => {
            return { value: item.id, label: item.title };
          })
        );
        dispatchGlobal(loading(false));
      }
    });
  }, []);

  useEffect(() => {
    if (polls.length && !createNewReport) {
      dispatchGlobal(loading(true));
      service.getSingleReport(reportId).then((resp) => {
        if (resp.status === 200) {
          dispatch(
            reportEdit({
              reportName: resp.data.name,
              reportPoll: polls.filter(
                (item) => item.value === resp.data.quiz?.id
              )[0],
              reportEmails: resp.data.emails
            })
          );
          setDates({
            from: moment(resp.data.start_date),
            to: moment(resp.data.end_date)
          });
          setSelectedPeriod(
            period.filter((item) => item.value === resp.data.report_period)[0]
          );
          if (resp.data.prev_start_date && resp.data.prev_end_date) {
            setComparisonPeriod(true);
            setComparisonDates({
              from: moment(resp.data.prev_start_date),
              to: moment(resp.data.prev_end_date)
            });
          }
          setSelectedSendingFrequencys(
            sendingFrequencys.filter(
              (item) => item.value === resp.data.period_create
            )[0]
          );
          dispatchGlobal(loading(false));
        }
      });
    }
  }, [polls]);

  const validateName = (value) => {
    dispatch(
      reportErrors({
        requiredReportName: !value,
        reportNameLength: !validateNameLength(value, 1, 26)
      })
    );
  };

  const validatePolls = (value) => {
    dispatch(
      reportErrors({
        requiredReportPoll: !Object.keys(value).length
      })
    );
  };

  // const validateEmail = (value) => {
  //   dispatch(
  //     reportErrors({
  //       requiredReportEmails: !value.length
  //     })
  //   );
  // };

  require(`moment/locale/ru`);
  require(`moment/locale/uk`);
  const locale = i18n.language === 'ru' ? 'ru' : 'uk';

  const validateFields = () => {
    dispatch(reportTouched());
    validateName(state.reportName.value);
    validatePolls(state.reportPoll.value);
    // validateEmail(state.reportEmails.value);
    return (
      !(
        (
          state.reportErrors.requiredReportName ||
          state.reportErrors.reportNameLength ||
          state.reportErrors.requiredReportPoll
        )
        // || state.reportErrors.requiredReportEmails
      ) &&
      state.reportName.value &&
      Object.keys(state.reportPoll.value).length
      // && state.reportEmails.value.length
    );
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;

    const createReportData = {
      name: state.reportName.value,
      quiz_id: state.reportPoll.value.value,
      report_period: selectedPeriod.value,
      period_create: selectedSendingFrequencys.value,
      start_date: dates.from.format('YYYY-MM-DD'),
      end_date: dates.to.format('YYYY-MM-DD'),
      emails: state.reportEmails.value
    };
    const comparePeriod = {
      prev_start_date: comparisonPeriod
        ? comparisonDates.from.format('YYYY-MM-DD')
        : '',
      prev_end_date: comparisonPeriod
        ? comparisonDates.to.format('YYYY-MM-DD')
        : ''
    };

    createNewReport
      ? service
          .createReport(
            comparisonPeriod
              ? { ...createReportData, ...comparePeriod }
              : createReportData
          )
          .then((resp) => {
            if (resp.status === 201) {
              notify();
              history.push('/reports');
            }
          })
      : service
          .updateReport(
            reportId,
            comparisonPeriod
              ? { ...createReportData, ...comparePeriod }
              : {
                  ...createReportData,
                  prev_start_date: null,
                  prev_end_date: null
                }
          )
          .then((resp) => {
            if (resp.status === 200) {
              notify();
              history.push('/reports');
            }
          });
  };

  const deleteReport = () => {
    service.deleteReport(reportId).then((resp) => {
      if (resp.status === 204) {
        deleteNotify();
        history.push('/reports');
      }
    });
  };

  return (
    <DefaultInner>
      <PageContent>
        <PrevPage to="/reports" pageName={t('reportsPage.backToReports')} />
        <div className="content__header">
          <h1 className="content__title">
            {createNewReport
              ? t('reportsPage.createNewReport')
              : t('reportsPage.editReport')}
          </h1>
          {!createNewReport && (
            <DeleteButton
              text={t('reportsPage.deleteReport')}
              openModal={() => setShowDeleteModal(true)}
            />
          )}
        </div>
        {stateGlobal.isLoading ? (
          <Loading />
        ) : (
          <div className="content__box">
            <div className="page-form">
              <div className="page-form__container">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlerSubmit();
                  }}
                >
                  <div className="form-group pb-4 mb-0 has-label-line">
                    <label htmlFor="report-name">
                      <span className="required-label">*</span>
                      {t('reportsPage.nameReport')}
                    </label>
                    <input
                      autoComplete="off"
                      className={`form-control ${
                        state.reportName.touched &&
                        !state.reportErrors.requiredReportName &&
                        !state.reportErrors.reportNameLength
                          ? 'is-valid'
                          : state.reportName.touched &&
                            (state.reportErrors.requiredReportName ||
                              state.reportErrors.reportNameLength)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="report-name"
                      type="text"
                      name="reportName"
                      value={state.reportName.value}
                      onChange={(e) => {
                        dispatch(reportName(e.target.value));
                        validateName(e.target.value);
                      }}
                    />
                    {state.reportErrors.requiredReportName && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.required')}</span>
                      </div>
                    )}
                    {state.reportErrors.reportNameLength &&
                      !state.reportErrors.requiredReportName && (
                        <div className="error">
                          <span>{t('ERROR_TEXT.length26Error')}</span>
                        </div>
                      )}
                    <span className="label-number">1</span>
                  </div>
                  <div className="form-group pb-4 mb-0 has-label-line">
                    <label htmlFor="report-type">
                      <span className="required-label">*</span>
                      {t('reportsPage.chose')}
                    </label>
                    <div className="form-group-radio mb-3">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          className="custom-control-input"
                          id="poll"
                          type="radio"
                          name="type"
                          checked
                          readOnly
                        />
                        <label className="custom-control-label" htmlFor="poll">
                          {t('reportsPage.smallPoll')}
                        </label>
                      </div>
                    </div>
                    <Select
                      styles={customStyles}
                      options={polls}
                      value={state.reportPoll.value}
                      placeholder={t('reportsPage.chosePoll')}
                      error={state.reportErrors.requiredReportPoll}
                      onChange={(e) => {
                        dispatch(reportPoll(e));
                        validatePolls(e);
                      }}
                    />
                    {state.reportErrors.requiredReportPoll && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.required')}</span>
                      </div>
                    )}
                    <span className="label-number">2</span>
                  </div>
                  <div className="form-group pb-4 mb-0">
                    <label htmlFor="report-period-first">
                      {t('reportsPage.chosePeriod')}
                    </label>
                    <Select
                      styles={customStyles}
                      options={period}
                      value={selectedPeriod}
                      onChange={(e) => {
                        setSelectedPeriod(e);
                        datesSetter(e.value);
                      }}
                    />
                    <span className="label-number">3</span>
                    {selectedPeriod.value === 'userDateRange' && (
                      <div
                        className="form-group-datapicker mt-3"
                        id="report-datapicker-first"
                      >
                        <div className="page-datepicker">
                          <div className="page-datepicker__input-container">
                            <div className="page-datepicker__input">
                              <Datetime
                                locale={locale}
                                timeFormat={false}
                                value={dates.from}
                                onChange={(e) => {
                                  comparisonPeriod
                                    ? datesAndComparisonDatesSetter(
                                        moment(e),
                                        'datesFrom'
                                      )
                                    : setDates({
                                        ...dates,
                                        from: moment(e)
                                      });
                                }}
                                closeOnSelect
                                isValidDate={(current) =>
                                  current.isBefore(dates.to)
                                }
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>
                          <div className="page-datepicker__input-container">
                            <div className="page-datepicker__input">
                              <Datetime
                                locale={locale}
                                timeFormat={false}
                                value={dates.to}
                                onChange={(e) => {
                                  comparisonPeriod
                                    ? datesAndComparisonDatesSetter(
                                        moment(e),
                                        'datesTo'
                                      )
                                    : setDates({
                                        ...dates,
                                        to: moment(e)
                                      });
                                }}
                                closeOnSelect
                                isValidDate={(current) =>
                                  current.isAfter(dates.from) &&
                                  current.isBefore(moment())
                                }
                                inputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="form-group-periods d-flex align-items-center mt-3">
                      <span className="page-switch-label mr-3">
                        {t('reportsPage.includeComparisonPeriod')}
                      </span>
                      <label className="page-switch">
                        <input
                          type="checkbox"
                          name="periods"
                          checked={comparisonPeriod}
                          onChange={() =>
                            setComparisonPeriod(!comparisonPeriod)
                          }
                        />
                        <span className="page-switch__slider" />
                      </label>
                    </div> */}
                    {/* HERE IF PERIOD */}
                    {/* {comparisonPeriod &&
                      selectedPeriod.value !== 'userDateRange' && (
                        <input
                          className="form-control mt-3"
                          value={
                            comparisonPeriodValues[selectedPeriod.value].label
                          }
                          disabled
                        />
                      )}
                    {comparisonPeriod &&
                      selectedPeriod.value === 'userDateRange' && (
                        <div
                          className="form-group-datapicker mt-3"
                          id="report-datapicker-first"
                        >
                          <div className="page-datepicker">
                            <div className="page-datepicker__input-container">
                              <div className="page-datepicker__input">
                                <Datetime
                                  locale={locale}
                                  timeFormat={false}
                                  value={comparisonDates.from.format(
                                    'DD.MM.YYYY'
                                  )}
                                  onChange={(e) =>
                                    datesAndComparisonDatesSetter(
                                      moment(e),
                                      'comparisonDatesFrom'
                                    )
                                  }
                                  closeOnSelect
                                  isValidDate={(current) =>
                                    current.isBefore(comparisonDates.to)
                                  }
                                  inputProps={{ readOnly: true }}
                                />
                              </div>
                            </div>
                            <div className="page-datepicker__input-container">
                              <div className="page-datepicker__input">
                                <Datetime
                                  locale={locale}
                                  timeFormat={false}
                                  value={comparisonDates.to.format(
                                    'DD.MM.YYYY'
                                  )}
                                  onChange={(e) =>
                                    datesAndComparisonDatesSetter(
                                      moment(e),
                                      'comparisonDatesTo'
                                    )
                                  }
                                  closeOnSelect
                                  isValidDate={(current) =>
                                    current.isBefore(
                                      dates.from.clone().subtract(1, 'd')
                                    )
                                  }
                                  inputProps={{ readOnly: true }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                    {/* END OF PERIOD */}
                  </div>
                  {/* <div className="form-group pb-4 mb-0 has-label-line">
                    <label htmlFor="periodicity">
                      {t('reportsPage.sendingFrequency')}
                    </label>
                    <Select
                      styles={customStyles}
                      options={sendingFrequencys}
                      value={selectedSendingFrequencys}
                      onChange={(e) => setSelectedSendingFrequencys(e)}
                    />
                    <span className="label-number">4</span>
                  </div> */}
                  {/* <div className="form-group pb-4 mb-0">
                    <label htmlFor="send">
                      <span className="required-label">*</span>
                      {t('reportsPage.sendReportToMail')}
                    </label>
                    <div
                      className={
                        state.reportEmails.touched &&
                        !state.reportErrors.requiredReportEmails
                          ? 'react-multi-email-wrap is-valid'
                          : state.reportErrors.requiredReportEmails
                          ? 'react-multi-email-wrap is-invalid'
                          : 'react-multi-email-wrap'
                      }
                    >
                      <ReactMultiEmail
                        placeholder="Email"
                        emails={state.reportEmails.value}
                        onChange={(_emails) => {
                          dispatch(reportEmails(_emails));
                          validateEmail(_emails);
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                    {state.reportErrors.requiredReportEmails && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.required')}</span>
                      </div>
                    )}
                    <span className="label-number">5</span>
                  </div> */}
                  <div className="form-group has-submit-btn mb-0">
                    <MainButton
                      type="submit"
                      className="btn btn-confirm has-icon"
                      text={
                        createNewReport
                          ? t('reportsPage.create')
                          : t('reportsPage.save')
                      }
                    >
                      <span className="icon-btn">
                        <IconConfirm className="svg-sprite-icon icon-confirm" />
                      </span>
                    </MainButton>
                    <CancelButton
                      text={t('reportsPage.cancel')}
                      to="/reports"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <DeleteReportModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          deleteFunc={deleteReport}
          modalTitleText={t('reportsPage.deleteReport')}
        />
      </PageContent>
    </DefaultInner>
  );
};

export default NewOrEditReport;

NewOrEditReport.propTypes = {
  createNewReport: PropTypes.bool
};

NewOrEditReport.defaultProps = {
  createNewReport: false
};
