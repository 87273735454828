import React from 'react';
import Account from '../components/Account/Account';
import { PageContent } from '../components/StyleComponents';
import DefaultInner from './DefaultInner';

const AccountPage = () => {
  return (
    <DefaultInner>
      <PageContent>
        <Account />
      </PageContent>
    </DefaultInner>
  );
};

export default AccountPage;
