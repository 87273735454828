import React from 'react';
import Statistic from '../components/Statistic/Statistic';
import DefaultInner from './DefaultInner';

const StatisticPage = () => {
  return (
    <DefaultInner>
      <Statistic />
    </DefaultInner>
  );
};

export default StatisticPage;
