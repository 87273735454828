import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconClose } from '../../img/sprite/svg/close.svg';

const CancelButton = ({ text, to }) => {
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => history.push(to)}
      className="btn btn-cancel has-icon"
    >
      {text}
      <span className="icon-btn">
        <IconClose className="svg-sprite-icon icon-close" />
      </span>
    </button>
  );
};

export default CancelButton;

CancelButton.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string
};

CancelButton.defaultProps = {
  text: '',
  to: ''
};
