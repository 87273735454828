import moment from 'moment';

export const validateNameLength = (value, minLength, maxLength) => {
  return value.length >= minLength && value.length <= maxLength;
};

export const validatePasswordLength = (value, minLength) => {
  return value.length >= minLength;
};

export const validateEmailPattern = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const validatePasswordAtLeastOneUpperLowerDigit = (value) => {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  return re.test(value);
};

export const validatePasswordOnlyLatinLettersAndNumbers = (value) => {
  const re = /^[a-z][a-z0-9]*$/i;
  return re.test(value);
};

export const pagination = (current, last) => {
  const delta = 2;
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

export const saveAsFile = (text, filename, type) => {
  // Create the blob object
  const blob = new Blob([text], { type });

  // Create Blob Object URL for that blob
  const url = URL.createObjectURL(blob);

  // Trigger downloading the object using that URL
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click(); // triggering it manually
};

export const numberToFixed = (num, fixed) => {
  if (typeof num === 'number') return Number(num.toFixed(fixed));
  return null;
};

const dateFormats = {
  h: 'DD.MM.YYYY hh:mm',
  d: 'DD.MM.YYYY',
  w: 'DD.MM.YYYY',
  m: 'MM.YYYY',
  s: 'MM.YYYY',
  y: 'YYYY'
}

export const lineDataFunc = (dataRange, datesRange, trunc) => {
  return dataRange.map((csat, index) => {
    return {
      date: datesRange[index],
      data: numberToFixed(csat, 2)
    };
  });
};

const datesDiffConst = {
  hours: 'h',
  days: 'd',
  weeks: 'w',
  months: 'm',
  half: 's',
  years: 'y'
};

const diffFunc = (dateTo, dateFrom, timeFormat) => {
  return dateTo.diff(dateFrom, timeFormat);
};

export const datesDiff = (dateTo, dateFrom) => {
  if (diffFunc(dateTo, dateFrom, 'days') < 1) return datesDiffConst.hours;

  if (diffFunc(dateTo, dateFrom, 'days') <= 14) return datesDiffConst.days;

  if (diffFunc(dateTo, dateFrom, 'months') <= 3) return datesDiffConst.weeks;

  if (diffFunc(dateTo, dateFrom, 'months') <= 18) return datesDiffConst.months;

  if (diffFunc(dateTo, dateFrom, 'years') <= 3) return datesDiffConst.half;

  return datesDiffConst.years;
};
