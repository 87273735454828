import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

export const SpinnerContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = () => {
  return (
    <SpinnerContainerStyled>
      <Spinner animation="border" />
    </SpinnerContainerStyled>
  );
};

export default Loading;
