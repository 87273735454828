import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import ServiceAPI from '../../services';
import UserRow from './UserRow';
import { ReactComponent as IconPlus } from '../../img/sprite/svg/plus.svg';
import usePagination from '../../hooks/usePagination';
import Pagination from '../Pagination/Pagination';
import AddButton from '../Buttons/AddButton';
import Loading from '../Loading/Loading';
import { loading } from '../../actions';

const Users = () => {
  const service = new ServiceAPI();
  const { t } = useTranslation();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);

  const [currentUser, setCurrentUser] = useState({});
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    dispatchGlobal(loading(true));
    service.getUserInfo().then((resp) => {
      if (resp.status === 200) {
        service.getUsers().then((response) => {
          setCount(response.data.count);
          setCurrentUser(resp.data);
          setUsers([resp.data, ...response.data.results]);
          dispatchGlobal(loading(false));
        });
      }
    });
  }, []);

  const { next, prev, jump, currentPage, maxPage } = usePagination(
    count,
    process.env.REACT_APP_PAGINATION_NUM
  );

  const changePage = (pageNum) => {
    if (currentPage !== pageNum) {
      dispatchGlobal(loading(true));
      service
        .getUsers(
          pageNum > 1 && {
            limit: process.env.REACT_APP_PAGINATION_NUM,
            offset:
              pageNum * process.env.REACT_APP_PAGINATION_NUM -
              process.env.REACT_APP_PAGINATION_NUM
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            pageNum === 1
              ? setUsers([currentUser, ...resp.data.results])
              : setUsers(resp.data.results);
            dispatchGlobal(loading(false));
          }
        });
    }
  };

  return (
    <>
      <div className="content__header">
        <h1 className="content__title">{t('Users.pageName')}</h1>
        <AddButton text={t('Users.inviteNewUser')} linkTo="/users/addUser" />
      </div>
      <div className="content__panels">
        <div className="users">
          <div className="users__panel">
            <div className="users-panel">
              <div className="users-panel__head">
                <div className="col-4 users-panel__head-user">
                  {t('Users.user')}
                </div>
                <div className="col-3 users-panel__head-name">
                  {t('Users.names')}
                </div>
                <div className="col-2 users-panel__head-admin">
                  {t('Users.administrator')}
                </div>
                <div className="col-2 users-panel__head-owner">
                  {t('Users.owner')}
                </div>
                <div className="col-1 users-panel__head-edit" />
              </div>
              {stateGlobal.isLoading ? (
                <Loading />
              ) : (
                <Map
                  collection={users}
                  iteratee={(item) => {
                    return (
                      <UserRow
                        key={item?.id}
                        id={item?.id}
                        position={item?.position}
                        email={item?.email}
                        firstName={item?.first_name}
                        lastName={item?.last_name}
                        isAdmin={item?.is_admin}
                        isOwner={item?.is_owner}
                        isActive={item?.is_active}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="content__pagination content__pagination--has-btn">
        <Link to="/users/addUser" className="btn-panel-add">
          <span>
            {t('Users.inviteNewUser')}
            <span className="icon-btn">
              <IconPlus className="svg-sprite-icon icon-plus" />
            </span>
          </span>
        </Link>
        <Pagination
          currentPage={currentPage}
          maxPage={maxPage}
          next={next}
          prev={prev}
          jump={jump}
          changePage={changePage}
        />
      </div>
    </>
  );
};

export default Users;
