import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import ServiceAPI from '../../services';
import PageContent from '../StyleComponents/PageContent';
import AddButton from '../Buttons/AddButton';
import ReportRow from './ReportRow';
import { ReactComponent as IconSort } from '../../img/sprite/svg/sort.svg';
import { ReactComponent as IconPlus } from '../../img/sprite/svg/plus.svg';
import Loading from '../Loading/Loading';
import { loading } from '../../actions';
import Pagination from '../Pagination/Pagination';
import usePagination from '../../hooks/usePagination';

const Reports = () => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);

  const [reports, setReports] = useState([]);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState(null);

  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, process.env.REACT_APP_PAGINATION_NUM);

  const userIsOwnerOrAdmin =
    stateGlobal.user?.is_owner || stateGlobal.user?.is_admin;

  useEffect(() => {
    let mounted = true;
    dispatchGlobal(loading(true));
    if (mounted) {
      service.getReports().then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setReports(resp.data.results);
          dispatchGlobal(loading(false));
        }
      });
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    // Search
    if (searchText !== null) {
      const timeout = setTimeout(() => {
        dispatchGlobal(loading(true));
        const searchObj = searchText && { search: searchText };
        service.getReports(searchObj).then((resp) => {
          if (resp.status === 200) {
            setCount(resp.data.count);
            setReports(resp.data.results);
            setCurrentPage(1);
            dispatchGlobal(loading(false));
          }
        });
      }, 1000);

      // if this effect run again, because `inputValue` changed, we remove the previous timeout
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const changePage = (pageNum) => {
    if (currentPage !== pageNum) {
      dispatchGlobal(loading(true));
      const paginationObj = {
        limit: process.env.REACT_APP_PAGINATION_NUM,
        offset:
          pageNum * process.env.REACT_APP_PAGINATION_NUM -
          process.env.REACT_APP_PAGINATION_NUM
      };
      if (searchText) paginationObj.search = searchText;
      service.getReports(paginationObj).then((resp) => {
        if (resp.status === 200) {
          setReports(resp.data.results);
          dispatchGlobal(loading(false));
        }
      });
    }
  };

  return (
    <PageContent>
      <div className="content__header">
        <h1 className="content__title">{t('reportsPage.pageName')}</h1>
        {userIsOwnerOrAdmin && (
          <AddButton
            text={t('reportsPage.addNewReport')}
            linkTo="/reports/create"
          />
        )}
      </div>
      <div className="content__search">
        <div className="page-search">
          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder={t('reportsPage.search')}
                value={searchText || ''}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="content__panels">
        <div className="reports">
          <div className="reports__panel">
            <div className="reports-panel">
              <div className="reports-panel__head">
                <div className="col-2 reports-panel__head-device sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('reportsPage.name')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-4 reports-panel__head-polls sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('reportsPage.poll')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-3 reports-panel__head-period sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('reportsPage.period')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-2 reports-panel__head-download text-center sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('reportsPage.download')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-1 reports-panel__head-edit text-center" />
              </div>
              {stateGlobal.isLoading ? (
                <Loading />
              ) : (
                <Map
                  collection={reports}
                  iteratee={(item) => {
                    return (
                      <ReportRow
                        key={item.id}
                        report={item}
                        userIsOwnerOrAdmin={userIsOwnerOrAdmin}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="content__pagination content__pagination--has-btn">
        {userIsOwnerOrAdmin && (
          <Link className="btn-panel-add" to="/reports/create">
            <span>
              {t('reportsPage.addNewReport')}
              <span className="icon-btn">
                <IconPlus className="svg-sprite-icon icon-plus" />
              </span>
            </span>
          </Link>
        )}
        <div className="content__pagination">
          <Pagination
            currentPage={currentPage}
            maxPage={maxPage}
            next={next}
            prev={prev}
            jump={jump}
            changePage={changePage}
          />
        </div>
      </div>
    </PageContent>
  );
};

export default Reports;
