import {
  POLL_NAME,
  QUESTION_NAME,
  DEVICES,
  INITIAL_DEVICES,
  POLLS_ERRORS,
  POLL_TOUCHED,
  POLL_EDIT
} from '../constants/actionsTypes';

export const initialState = {
  pollName: { value: '', touched: false },
  questionName: { value: '', touched: false },
  devices: { value: [], touched: false },
  pollsErrors: {
    requiredPollName: false,
    pollNameLength: false,
    requiredQuestion: false,
    questionLength: false,
    requiredDevice: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case POLL_EDIT:
      return {
        ...state,
        pollName: { ...state.pollName, value: action.payload.pollName },
        questionName: {
          ...state.questionName,
          value: action.payload.questionName
        },
        devices: {
          ...state.devices,
          value: state.devices.value.map((item) => {
            if (action.payload.activeDevices.includes(item.id)) {
              return { ...item, checked: true };
            }
            return item;
          })
        }
      };
    case POLL_TOUCHED:
      return {
        ...state,
        pollName: { ...state.pollName, touched: true },
        questionName: { ...state.questionName, touched: true },
        devices: { ...state.devices, touched: true }
      };
    case INITIAL_DEVICES:
      return { ...state, devices: { value: action.payload, touched: false } };
    case POLL_NAME:
      return { ...state, pollName: { value: action.payload, touched: true } };
    case QUESTION_NAME:
      return {
        ...state,
        questionName: { value: action.payload, touched: true }
      };
    case DEVICES:
      return { ...state, devices: { value: action.payload, touched: true } };
    case POLLS_ERRORS:
      return {
        ...state,
        pollsErrors: { ...state.pollsErrors, ...action.payload }
      };
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
