import React from 'react';
import PropTypes from 'prop-types';
import PaginationBox from './PaginationBox';

const Pagination = ({ currentPage, maxPage, next, prev, jump, changePage }) => {
  return (
    <nav className="page-pagination" aria-label="Page navigation">
      <PaginationBox
        maxPage={maxPage}
        currentPage={currentPage}
        next={next}
        prev={prev}
        jump={jump}
        changePage={changePage}
      />
    </nav>
  );
};

export default Pagination;

Pagination.propTypes = {
  currentPage: PropTypes.number,
  maxPage: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
  jump: PropTypes.func,
  changePage: PropTypes.func
};

Pagination.defaultProps = {
  currentPage: 1,
  maxPage: 1,
  next: null,
  prev: null,
  jump: null,
  changePage: null
};
