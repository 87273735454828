import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ServiceAPI from '../../services';
import { newPassword, repeatNewPassword, error } from '../../actions';
import {
  validatePasswordLength,
  validatePasswordAtLeastOneUpperLowerDigit,
  validatePasswordOnlyLatinLettersAndNumbers
} from '../../usefulFunctions';
import reducer, { initialState } from '../../reducers/changeUserInfo';
import { LoginPagesContainer } from '../StyleComponents';
import MainButton from '../Buttons/MainButton';
import { ReactComponent as IconLock } from '../../img/sprite/svg/lock.svg';
import { ReactComponent as IconEye } from '../../img/sprite/svg/eye.svg';

const ResetPassword = () => {
  const { resetToken } = useParams();
  const service = new ServiceAPI();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const history = useHistory();

  const [visiblePassword, setVisiblePassword] = useState({
    newPassword: false,
    repeatNewPassword: false
  });

  const newPasswordMatch = (value, isNewPassword) => {
    if (isNewPassword) {
      return value === state.repeatNewPassword.value;
    }
    return value === state.newPassword.value;
  };

  const validateNewPasswordField = (value) => {
    dispatch(
      error({
        requiredNewPassword: !value,
        lengthNewPassword: !validatePasswordLength(value, 8),
        atLeastOneLowerUpperDigit: !validatePasswordAtLeastOneUpperLowerDigit(
          value
        ),
        onlyLatinLetters: !validatePasswordOnlyLatinLettersAndNumbers(value),
        notMath: !newPasswordMatch(value, true),
        passwordToCommon: !value
      })
    );
  };
  const validateRepeatNewPasswordField = (value) => {
    dispatch(
      error({
        requiredRepeatNewPassword: !value,
        notMath: !newPasswordMatch(value, false),
        passwordToCommon: !value
      })
    );
  };

  const validateFields = () => {
    validateNewPasswordField(state.newPassword.value);
    validateRepeatNewPasswordField(state.repeatNewPassword.value);
    return (
      !(
        state.errors.requiredNewPassword ||
        state.errors.requiredRepeatNewPassword ||
        state.errors.notMath ||
        state.errors.lengthNewPassword
      ) &&
      state.newPassword.value &&
      state.repeatNewPassword.value
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e.target.value));
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    service
      .confirmResetPassword({
        new_password: state.newPassword.value,
        confirm_password: state.repeatNewPassword.value,
        token: resetToken
      })
      .then((resp) => {
        if (resp.status === 200) {
          history.push('/');
        } else if (
          resp.status === 400 &&
          resp.data?.non_field_errors &&
          resp.data?.non_field_errors[0] === 'This password is too common.'
        ) {
          dispatch(
            error({
              passwordToCommon: true
            })
          );
        }
      });
  };

  const resetContainer = <>
    <h1 className="login-form__title login-form__title--no-icon">
      {t('ResetPasswordPage.pageName')}
    </h1>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handlerSubmit();
      }}
    >
      <div className="form-group mb-4">
        <label htmlFor="restore-password">
          <span className="required-label">*</span>
          {t('ResetPasswordPage.newPassword')}
        </label>
        <div className="form-control-wrapper">
          <input
            autoComplete="off"
            className={
              state.newPassword.touched &&
              !state.errors.requiredNewPassword &&
              !state.errors.lengthNewPassword &&
              !state.errors.atLeastOneLowerUpperDigit &&
              !state.errors.passwordToCommon
                ? 'form-control form-control-password is-valid'
                : state.newPassword.touched &&
                  (state.errors.requiredNewPassword ||
                    state.errors.lengthNewPassword ||
                    state.errors.atLeastOneLowerUpperDigit ||
                    state.errors.passwordToCommon)
                ? 'form-control form-control-password is-invalid'
                : 'form-control form-control-password'
            }
            id="restore-password"
            type={visiblePassword.newPassword ? 'text' : 'password'}
            name="newPassword"
            value={state.newPassword.value}
            onChange={(e) => {
              handlerOnChangeField(e, newPassword);
              validateNewPasswordField(e.target.value);
            }}
          />
          <IconLock className="svg-sprite-icon icon-lock" />
          <span
            className={
              visiblePassword.newPassword
                ? 'toggle-password toggle-password-visible'
                : 'toggle-password'
            }
            toggle="#restore-password"
          >
            <IconEye
              className="svg-sprite-icon icon-eye"
              onClick={() => {
                setVisiblePassword({
                  ...visiblePassword,
                  newPassword: !visiblePassword.newPassword
                });
              }}
            />
          </span>
        </div>
        {state.errors.requiredNewPassword && (
          <div className="error">
            <span>{t('ERROR_TEXT.newPasswordRequired')}</span>
          </div>
        )}
        {(state.errors.lengthNewPassword ||
          state.errors.atLeastOneLowerUpperDigit) &&
          !state.errors.requiredNewPassword && (
            <div className="error">
              <span>{t('ERROR_TEXT.lengthPassword')}</span>
            </div>
          )}
      </div>
      <div className="form-group mb-5">
        <label htmlFor="restore-password-confirm">
          <span className="required-label">*</span>
          {t('ResetPasswordPage.repeatNewPassword')}
        </label>
        <div className="form-control-wrapper">
          <input
            autoComplete="off"
            className={
              state.repeatNewPassword.touched &&
              !state.errors.requiredRepeatNewPassword &&
              !state.errors.notMath &&
              !state.errors.passwordToCommon
                ? 'form-control form-control-password is-valid'
                : state.repeatNewPassword.touched &&
                  (state.errors.requiredRepeatNewPassword ||
                    state.errors.notMath ||
                    state.errors.passwordToCommon)
                ? 'form-control form-control-password is-invalid'
                : 'form-control form-control-password'
            }
            id="restore-password-confirm"
            type={visiblePassword.repeatNewPassword ? 'text' : 'password'}
            name="repeatPassword"
            value={state.repeatNewPassword.value}
            onChange={(e) => {
              handlerOnChangeField(e, repeatNewPassword);
              validateRepeatNewPasswordField(e.target.value);
            }}
          />
          <IconLock className="svg-sprite-icon icon-lock" />
          <span
            className={
              visiblePassword.repeatNewPassword
                ? 'toggle-password toggle-password-visible'
                : 'toggle-password'
            }
            toggle="#restore-password-confirm"
          >
            <IconEye
              className="svg-sprite-icon icon-eye"
              onClick={() => {
                setVisiblePassword({
                  ...visiblePassword,
                  repeatNewPassword: !visiblePassword.repeatNewPassword
                });
              }}
            />
          </span>
        </div>
        {state.errors.requiredRepeatNewPassword && (
          <div className="error">
            <span>{t('ERROR_TEXT.repeatNewPassword')}</span>
          </div>
        )}
        {state.errors.notMath &&
          state.repeatNewPassword.touched &&
          !state.errors.requiredRepeatNewPassword && (
            <div className="error">
              <span>{t('ERROR_TEXT.passworsdNotMatch')}</span>
            </div>
          )}
        {state.errors.passwordToCommon &&
          state.repeatNewPassword.touched &&
          !state.errors.notMath &&
          !state.errors.requiredRepeatNewPassword && (
            <div className="error">
              <span>{t('ERROR_TEXT.passwordToCommon')}</span>
            </div>
          )}
      </div>
      <div className="form-group mb-0">
        <MainButton
          type="submit"
          className="btn btn-confirm d-block w-100"
          text={t('ResetPasswordPage.save')}
        />
      </div>
    </form>
  </>

  return (
    <LoginPagesContainer
      child1={resetContainer}
    />
  );
};

export default ResetPassword;
