import {
  LOGIN_EMAIL,
  LOGIN_PASSWORD,
  LOGIN_REMEMBER_ME,
  LOGIN_ERRORS
} from '../constants/actionsTypes';

export const initialState = {
  email: { value: '', touched: false },
  password: { value: '', touched: false },
  rememberMe: false,
  errors: {
    requiredEmail: false,
    emailPattern: false,
    requiredPassword: false,
    emailOrPasswordNotMath: false,
    emailNotFound: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN_EMAIL:
      return { ...state, email: { value: action.payload, touched: true } };
    case LOGIN_PASSWORD:
      return { ...state, password: { value: action.payload, touched: true } };
    case LOGIN_REMEMBER_ME:
      return { ...state, rememberMe: action.payload };
    case LOGIN_ERRORS:
      return { ...state, errors: { ...state.errors, ...action.payload } };
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
