import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../context/globalContext';
import { helpDesk } from '../../actions';
import { ReactComponent as IconAlert } from '../../img/sprite/svg/alert.svg';

const HelpDesk = () => {
  const { t } = useTranslation();
  const { dispatchGlobal } = useContext(GlobalContext);

  return (
    <p className="support-help-text">
      <IconAlert className="svg-sprite-icon icon-alert" />
      {t('HelpDesk.toChange')}
      <u
        onClick={() => {
          dispatchGlobal(helpDesk(true));
        }}
      >
        {t('HelpDesk.helpDesk')}
      </u>
    </p>
  );
};

export default HelpDesk;
