import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ChartLine from '../Charts/ChartLine';
import IndicatorPercent from './IndicatorPercent';
import { numberToFixed, lineDataFunc } from '../../usefulFunctions';
import { ReactComponent as IconSad } from '../../img/sprite/svg/sad.svg';
import { ReactComponent as IconMeh } from '../../img/sprite/svg/meh.svg';
import { ReactComponent as IconSmile } from '../../img/sprite/svg/smile.svg';
import Comments from './Comments';

const reverceOpinionConst = {
  button_3: 'bad',
  button_2: 'neutral',
  button_1: 'like'
};

const StatisticRow = ({
  data,
  showGraphic,
  currentPage,
  trunc,
  showAdditionalAnswersGraph
}) => {
  const { t } = useTranslation();
  const [showCsi, setShowCsi] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState({
    like: false,
    neutral: false,
    bad: false
  });

  const lineData = (line) => {
    return lineDataFunc(line, data.current.date_range, trunc);
  };

  useEffect(() => {
    if (data?.current?.polls.length > 0) {
      const newAdditionalQuestions = {};
      data.current.polls.forEach((item) => {
        if (item.settings?.conditions.length) {
          item.settings.conditions.forEach((condition) => {
            const key = reverceOpinionConst[condition];
            newAdditionalQuestions[key] = true;
          });
        }
      });
      if (Object.keys(newAdditionalQuestions)) {
        newAdditionalQuestions.data = data;
        setAdditionalQuestions(newAdditionalQuestions);
      }
    }
  }, [data]);

  const pollsAllQuestions = (questions) => {
    let currentIter = 0;
    const firstFourQuestions = [];
    for (let i = 0; i < questions.length; i++) {
      if (currentIter === 4) break;
      firstFourQuestions.push(questions[i]);
      currentIter += 1;
    }
    return firstFourQuestions;
  };

  const percent = (l, target, total) => {
    if (total === 0 || l.every((item) => item === 0)) return [0, 0, 0];
    const off =
      target -
      _.reduce(
        l,
        function (acc, x) {
          return acc + Math.round(x);
        },
        0
      );
    return _.chain(l)
      .map(function (x, i) {
        return Math.round(x) + (off > i) - (i >= l.length + off);
      })
      .value();
  };
  const like = (data.current.like * 100) / data.current.total || 0;

  const neutral = (data.current.neutral * 100) / data.current.total || 0;

  const unlike = (data.current.unlike * 100) / data.current.total || 0;

  const otherRanges = {
    like: data.current.like_range,
    neutral: data.current.neutral_range,
    unlike: data.current.unlike_range
  };

  // if (data.is_total && currentPage !== 1) return null;
  const csat = t('statisticPage.indexClientHappy');
  const nps = t('statisticPage.indexclientLoyalty');
  return (
    <div className="content__panel">
      <div className="statistics">
        <div className="statistics__container">
          <div className="row">
            <div className="statistics__col statistics__col--topic col-md-12 col-lg-3">
              <div className="statistics-info">
                <div className="statistics-info__row">
                  {data.is_total ? (
                    <>
                      <p className="statistics-info__overal-title">
                        {t('statisticPage.total')}
                      </p>
                      <span className="statistics-info__label">
                        {`${t('statisticPage.polls')}:`}
                      </span>
                      <ul className="statistics-info__list">
                        {data.is_total ? (
                          <Map
                            collection={pollsAllQuestions(data.quiz_titles)}
                            iteratee={(item, index) => {
                              return (
                                <li key={index} className="text-truncate">
                                  {item}
                                </li>
                              );
                            }}
                          />
                        ) : (
                          <li>{data.quiz_title || ''}</li>
                        )}
                        {/* {data.questions.length > 3 && <li>...</li>} */}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="statistics-info__label">
                        {`${t('statisticPage.singlePoll')}:`}
                      </span>
                      <p className="statistics-info__text">
                        {data.title || ''}
                      </p>
                      <span className="statistics-info__label">
                        {`${t('statisticPage.question')}:`}
                      </span>
                      <p className="statistics-info__text statistics-info__text--question">
                        {data.current?.questions[0] || ''}
                      </p>
                    </>
                  )}
                </div>
                {!data.is_total && (
                  <div className="statistics-info__row">
                    <p className="statistics-info__label mb-0">
                      {data.current?.devices.length
                        ? data.current?.devices[0]
                        : ''}
                    </p>
                    {/* front update v2 14 */}
                    {/* <p className="statistics-info__update mb-0">
                          {`${t('statisticPage.lastUpdate')} ${moment(
                            data.updated
                          ).format('DD.MM.YY | hh:mm')}`}
                        </p> */}
                  </div>
                )}
              </div>
            </div>
            <div className="statistics__col statistics__col--scores col-md-6 col-lg-2">
              <div className="indicators">
                <div className="indicators__togglers">
                  <div className="indicators-togglers">
                    <ul className="nav" role="tablist">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <b>Customer Satisfaction Score</b>
                            <br />
                            {csat}
                          </Tooltip>
                        }
                      >
                        <li>
                          <a
                            className={showCsi ? 'active' : ''}
                            data-toggle="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCsi(true);
                            }}
                            role="tab"
                            href="#csat"
                          >
                            CSAT
                          </a>
                        </li>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            <b>Net Promoter Score</b>
                            <br />
                            {nps}
                          </Tooltip>
                        }
                      >
                        <li>
                          <a
                            className={!showCsi ? 'active' : ''}
                            data-toggle="tab"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCsi(false);
                            }}
                            role="tab"
                            href="#nps"
                          >
                            NPS
                          </a>
                        </li>
                      </OverlayTrigger>
                    </ul>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className={
                      showCsi ? 'tab-pane fade show active' : 'tab-pane fade'
                    }
                    role="tabpanel"
                  >
                    <p className="indicators__score">
                      {data.current?.csat
                        ? `${numberToFixed(data.current.csat, 2)}%`
                        : 0}
                    </p>
                    {data.current?.csat !== null &&
                      data.prev?.csat !== null && (
                        <IndicatorPercent
                          currentNum={data.current?.csat}
                          prevNum={data.prev?.csat}
                          npsOrCsat
                          csat
                        />
                      )}
                    <div className="indicators__chart">
                      <ChartLine
                        lineData={lineData(data.current?.csat_range)}
                        lineColor="#CB4D5F"
                        showGraphic={() =>
                          showGraphic(
                            lineData(data.current?.csat_range),
                            'CSAT',
                            otherRanges
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={
                      !showCsi ? 'tab-pane fade show active' : 'tab-pane fade'
                    }
                    role="tabpanel"
                  >
                    <p className="indicators__score">
                      {data.current?.nps
                        ? `${numberToFixed(data.current?.nps, 2)}`
                        : 0}
                    </p>
                    {data.current?.nps !== null && data.prev?.nps !== null && (
                      <IndicatorPercent
                        currentNum={data.current?.nps}
                        prevNum={data.prev?.nps}
                        npsOrCsat
                      />
                    )}
                    <div className="indicators__chart">
                      <ChartLine
                        lineData={lineData(data.current?.nps_range)}
                        lineColor="#F4780F"
                        showGraphic={() =>
                          showGraphic(
                            lineData(data.current?.nps_range),
                            'NPS',
                            otherRanges
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="statistics__col statistics__col--indicators col-md-6 col-lg-2">
              <div className="indicators">
                <p className="indicators__top">{t('statisticPage.answers')}</p>
                <p className="data indicators__score">
                  {data.current?.total}
                  {/* <small>{t('statisticPage.thousand')}</small> */}
                </p>
                {data.current?.total !== null && data.prev?.total !== null && (
                  <IndicatorPercent
                    currentNum={data.current?.total}
                    prevNum={data.prev?.total}
                  />
                )}

                <div className="indicators__chart">
                  <ChartLine
                    lineData={lineData(data.current?.total_range)}
                    lineColor="#74ADE6"
                    showGraphic={() =>
                      showGraphic(
                        lineData(data.current?.total_range),
                        'answers',
                        otherRanges
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="statistics__col statistics__col--rate col-lg-5">
              <div className="row">
                <div className="statistics__col-rate col-lg-4">
                  <div className="rate">
                    <p className="rate__icon">
                      <IconSad className="svg-sprite-icon icon-sad" />
                    </p>
                    <p className="rate__score">
                      {`${
                        percent(
                          [like, neutral, unlike],
                          100,
                          data.current?.total
                        )[2]
                      }%`}
                    </p>
                    <p className="rate__quantity">{data.current?.unlike}</p>
                    <IndicatorPercent
                      currentNum={data.current?.unlike}
                      prevNum={data.prev?.unlike}
                    />
                    {!data.is_total && additionalQuestions.bad && (
                      <Comments
                        showAdditionalAnswersGraph={showAdditionalAnswersGraph}
                        quizId={data.current?.quiz_ids[0]}
                        questionId={data.current?.question_ids[0]}
                        additionalQuestionsData={additionalQuestions.data}
                        button="button_3"
                      />
                    )}
                  </div>
                </div>
                <div className="statistics__col-rate col-lg-4">
                  <div className="rate">
                    <p className="rate__icon">
                      <IconMeh className="svg-sprite-icon icon-meh" />
                    </p>
                    <p className="rate__score">{`${
                      percent(
                        [like, neutral, unlike],
                        100,
                        data.current?.total
                      )[1]
                    }%`}</p>
                    <p className="rate__quantity">{data.current?.neutral}</p>
                    <IndicatorPercent
                      currentNum={data.current?.neutral}
                      prevNum={data.prev?.neutral}
                    />
                    {!data.is_total && additionalQuestions.neutral && (
                      <Comments
                        showAdditionalAnswersGraph={showAdditionalAnswersGraph}
                        quizId={data.current?.quiz_ids[0]}
                        questionId={data.current?.question_ids[0]}
                        additionalQuestionsData={additionalQuestions.data}
                        button="button_2"
                      />
                    )}
                  </div>
                </div>
                <div className="statistics__col-rate col-lg-4">
                  <div className="rate">
                    <p className="rate__icon">
                      <IconSmile className="svg-sprite-icon icon-smile" />
                    </p>
                    <p className="rate__score">{`${
                      percent(
                        [like, neutral, unlike],
                        100,
                        data.current?.total
                      )[0]
                    }%`}</p>
                    <p className="rate__quantity">{data.current?.like}</p>
                    <IndicatorPercent
                      currentNum={data.current?.like}
                      prevNum={data.prev?.like}
                    />
                    {!data.is_total && additionalQuestions.like && (
                      <Comments
                        showAdditionalAnswersGraph={showAdditionalAnswersGraph}
                        quizId={data.current?.quiz_ids[0]}
                        questionId={data.current?.question_ids[0]}
                        button="button_1"
                        additionalQuestionsData={additionalQuestions.data}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticRow;

StatisticRow.propTypes = {
  data: PropTypes.shape({}),
  showGraphic: PropTypes.func,
  currentPage: PropTypes.number,
  trunc: PropTypes.string,
  showAdditionalAnswersGraph: PropTypes.func
};

StatisticRow.defaultProps = {
  data: null,
  showGraphic: null,
  currentPage: null,
  trunc: '',
  showAdditionalAnswersGraph: null
};
