import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ReactComponent as IconDelete } from '../../img/sprite/svg/delete.svg';

const DeleteReportModal = ({ show, onHide, deleteFunc, modalTitleText }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} className="modal-delete">
      <Modal.Header>
        <span className="modal-icon">
          <IconDelete />
        </span>
        <Modal.Title>{modalTitleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('reportsPage.afterThis')}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-modal-cancel"
          type="button"
          onClick={() => onHide()}
        >
          {t('reportsPage.cancel')}
        </button>
        <button
          className="btn btn-modal-confirm"
          type="button"
          onClick={() => deleteFunc()}
        >
          {t('reportsPage.delete')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReportModal;

DeleteReportModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  deleteFunc: PropTypes.func,
  modalTitleText: PropTypes.string
};

DeleteReportModal.defaultProps = {
  show: false,
  onHide: null,
  deleteFunc: null,
  modalTitleText: ''
};
