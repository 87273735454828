import React from 'react';
import PropTypes from 'prop-types';
import IndicatorPercent from '../Statistic/IndicatorPercent';
import ChartLine from '../Charts/ChartLine';
import { lineDataFunc } from '../../usefulFunctions';

const RatingRowAnswers = ({
  item,
  index,
  pollsOrAddress,
  showGraphic,
  currentPage,
  trunc
}) => {
  const lineData = lineDataFunc(
    item.current.total_range,
    item.current.date_range,
    trunc
  );

  const otherRanges = {
    like: item.current.like_range,
    neutral: item.current.neutral_range,
    unlike: item.current.unlike_range
  };

  return (
    <div className="rating-panel__body">
      <div className="col-3">{item.title || ''}</div>
      <div className="col-3">
        <div className="indicators">
          <div className="indicators__chart">
            <ChartLine
              lineData={lineData}
              lineColor="#74ADE6"
              showGraphic={() => showGraphic(lineData, 'answers', otherRanges)}
            />
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="indicators">
          <p className="indicators__score">{item.current.total}</p>
          {item.current.total && item.prev.total && (
            <IndicatorPercent
              currentNum={item.current.total}
              prevNum={item.prev.total}
            />
          )}
        </div>
      </div>
      <div className="col-3">
        <div className="indicators">
          <p className="indicators__score">
            {index + 1 + currentPage * 10 - 10}
          </p>
          {/* <IndicatorPercent number={0} /> */}
        </div>
      </div>
    </div>
  );
};

export default RatingRowAnswers;

RatingRowAnswers.propTypes = {
  item: PropTypes.shape({}),
  index: PropTypes.number,
  pollsOrAddress: PropTypes.string,
  showGraphic: PropTypes.func,
  currentPage: PropTypes.number,
  trunc: PropTypes.string
};

RatingRowAnswers.defaultProps = {
  item: null,
  index: null,
  pollsOrAddress: '',
  showGraphic: null,
  currentPage: null,
  trunc: ''
};
