import React from 'react';
import PropTypes from 'prop-types';

const PageInner = ({ children }) => {
  return (
    <div className="page__inner">
      <div className="page__content">{children}</div>
    </div>
  );
};

export default PageInner;

PageInner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
