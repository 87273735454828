import React from 'react';
import Rating from '../components/Rating/Rating';
import DefaultInner from './DefaultInner';

const RatingPage = () => {
  return (
    <DefaultInner>
      <Rating />
    </DefaultInner>
  );
};

export default RatingPage;
