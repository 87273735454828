import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ServiceAPI from '../../services';
import reducer, { initialState } from '../../reducers/changeDeviceInfo';
import {
  changeDeviceInfo,
  deviceName,
  deviceId,
  deviceAddress,
  deviceError
} from '../../actions';
import { validateNameLength } from '../../usefulFunctions';
import PageContent from '../StyleComponents/PageContent';
import PrevPage from '../Navigation/PrevPage';
import DeleteButton from '../Buttons/DeleteButton';
import MainButton from '../Buttons/MainButton';
import CancelButton from '../Buttons/CancelButton';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';
import HelpDesk from '../StyleComponents/HelpDesk';
import DefaultInner from '../../Layouts/DefaultInner';

const DeviceEdit = () => {
  const { singleDeviceId } = useParams();
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  const notify = () => toast(t('ChangesSaves.changesIsSaved'));

  useEffect(() => {
    service.getSingleDevice(singleDeviceId).then((resp) => {
      if (resp.status === 200) {
        dispatch(changeDeviceInfo(resp.data));
      }
    });
  }, []);

  const validateDeviceNameField = (value) => {
    dispatch(
      deviceError({
        requiredDeviceName: !value,
        lengthDeviceName: !validateNameLength(value, 1, 26)
      })
    );
  };

  const validateDeviceIdField = (value) => {
    dispatch(
      deviceError({
        requiredDeviceId: !value
        // lengthDeviceId: !validateNameLength(value, 1, 52)
      })
    );
  };

  const validateDeviceAddressField = (value) => {
    dispatch(
      deviceError({
        requiredDeviceAddress: !value
        // lengthDeviceAddress: !validateNameLength(value, 1, 254)
      })
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e.target.value));
  };

  const validateFields = () => {
    validateDeviceNameField(state.deviceName.value);
    validateDeviceIdField(state.deviceId.value);
    validateDeviceAddressField(state.deviceAddress.value);
    return (
      !(
        state.deviceErrors.requiredDeviceName ||
        state.deviceErrors.requiredDeviceId ||
        state.deviceErrors.requiredDeviceAddress ||
        state.deviceErrors.lengthDeviceName ||
        state.deviceErrors.lengthDeviceId ||
        state.deviceErrors.lengthDeviceAddress
      ) &&
      state.deviceName.value &&
      state.deviceId.value &&
      state.kind.value &&
      state.deviceAddress.value
    );
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    service
      .updateSingleDevice(singleDeviceId, {
        name: state.deviceName.value,
        // id: state.deviceId.value,
        // kind: state.kind.value,
        address: state.deviceAddress.value
      })
      .then((resp) => {
        if (resp.status === 200) {
          notify();
          history.push('/devices');
        }
      });
  };

  const redirectBack = () => {
    // redirect back to devices page
    history.push('/devices');
  };

  const deleteDevice = () => {
    service.deleteSingleDevice(singleDeviceId).then(() => {
      redirectBack();
    });
  };

  return (
    <DefaultInner>
      <PageContent>
        <PrevPage to="/devices" pageName={t('devicesPage.devices')} />
        <div className="content__header">
          <h1 className="content__title">{t('DeviceEdit.pageName')}</h1>
          {/* <DeleteButton text={t('DeviceEdit.delete')} deleteFunc={deleteDevice} /> */}
        </div>
        <div className="content__box">
          <div className="page-form">
            <div className="page-form__container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlerSubmit();
                }}
              >
                <div className="form-group pb-4 mb-0 has-label-line">
                  <label htmlFor="device-name">
                    <span className="required-label">*</span>
                    {t('DeviceEdit.deviceName')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.deviceName.touched &&
                      !state.deviceErrors.requiredDeviceName &&
                      !state.deviceErrors.lengthDeviceName
                        ? 'form-control is-valid'
                        : state.deviceErrors.requiredDeviceName ||
                          state.deviceErrors.lengthDeviceName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="device-name"
                    type="text"
                    name="deviceName"
                    value={state.deviceName.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, deviceName);
                      validateDeviceNameField(e.target.value);
                    }}
                  />
                  <span className="label-number">1</span>
                  {state.deviceErrors.requiredDeviceName && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                  {state.deviceErrors.lengthDeviceName &&
                    !state.deviceErrors.requiredDeviceName && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.length26Error')}</span>
                      </div>
                    )}
                </div>
                <div className="form-group pb-4 mb-0 has-label-line">
                  <label htmlFor="device-id">
                    {/* <span className="required-label">*</span> */}
                    {t('DeviceEdit.deviceId')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.deviceId.touched &&
                      !state.deviceErrors.requiredDeviceId
                        ? 'form-control is-valid'
                        : state.deviceErrors.requiredDeviceId
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="device-id"
                    type="number"
                    name="deviceId"
                    value={state.deviceId.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, deviceId);
                      validateDeviceIdField(e.target.value);
                    }}
                    disabled
                  />
                  <span className="label-number">2</span>
                  <HelpDesk />
                  {state.deviceErrors.requiredDeviceId && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group pb-4 mb-0 has-label-line">
                  <label htmlFor="device-type">
                    {/* <span className="required-label">*</span> */}
                    {t('DeviceEdit.deviceType')}
                  </label>
                  <input
                    autoComplete="off"
                    className="form-control"
                    id="device-type"
                    type="text"
                    name="kind"
                    value={state.kind.value || ''}
                    disabled
                  />
                  <span className="label-number">3</span>
                  <HelpDesk />
                </div>
                <div className="form-group pb-4 mb-0">
                  <label htmlFor="device-location">
                    {/* <span className="required-label">*</span> */}
                    {t('DeviceEdit.deviceAddress')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.deviceAddress.touched &&
                      !state.deviceErrors.requiredDeviceAddress
                        ? 'form-control is-valid'
                        : state.deviceErrors.requiredDeviceAddress
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="device-location"
                    type="text"
                    name="deviceAddress"
                    value={state.deviceAddress.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, deviceAddress);
                      validateDeviceAddressField(e.target.value);
                    }}
                    disabled
                  />
                  <span className="label-number">4</span>
                  <HelpDesk />
                  {state.deviceErrors.requiredDeviceAddress && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group has-submit-btn mb-0">
                  <MainButton
                    type="submit"
                    className="btn btn-confirm has-icon"
                    text={t('DeviceEdit.save')}
                  >
                    <span className="icon-btn">
                      <IconConfirm className="svg-sprite-icon icon-confirm" />
                    </span>
                  </MainButton>
                  <CancelButton text={t('DeviceEdit.cancel')} to="/devices" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageContent>
    </DefaultInner>
  );
};

export default DeviceEdit;
