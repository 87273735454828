import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ServiceAPI from '../../services';
import EditButton from '../Buttons/EditButton';
import { ReactComponent as IconCopyLink } from '../../img/sprite/svg/link.svg';

const PollRow = ({
  id,
  name,
  question,
  status,
  userIsOwnerOrAdmin,
  voteLink
}) => {
  const { t } = useTranslation();
  const service = new ServiceAPI();
  const [checked, setChecked] = useState(status);

  const notify = () => toast(t('pollsPage.linkCopied'));

  const changeStatus = (pollId) => {
    setChecked(!checked);
    service.changePoll(pollId, { is_active: !checked });
  };

  return (
    <div className="polls-panel__body">
      <div className="col-4">{name || ''}</div>
      {voteLink ? (
        <div className="col-5 has-copy-link">
          {question || ''}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('pollsPage.copyLink')}</Tooltip>}
          >
            <span
              className="copy-link"
              role="button"
              onClick={() => {
                navigator.clipboard.writeText(voteLink);
                notify();
              }}
            >
              <IconCopyLink className="svg-sprite-icon icon-link" />
            </span>
          </OverlayTrigger>
        </div>
      ) : (
        <div className="col-5">{question || ''}</div>
      )}

      <div className="col-2 text-center">
        <label className="page-switch">
          <input
            type="checkbox"
            name="name1"
            checked={checked}
            onChange={() => changeStatus(id)}
            disabled={!userIsOwnerOrAdmin}
          />
          <span className="page-switch__slider" />
        </label>
      </div>
      <div className="col-1">
        {userIsOwnerOrAdmin && (
          <EditButton
            tooltipText={t('pollsPage.edit')}
            linkTo={`/polls/${id}`}
          />
        )}
      </div>
    </div>
  );
};

export default PollRow;

PollRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.string,
  status: PropTypes.bool,
  userIsOwnerOrAdmin: PropTypes.bool,
  voteLink: PropTypes.string
};

PollRow.defaultProps = {
  id: null,
  name: '',
  question: '',
  status: false,
  userIsOwnerOrAdmin: false,
  voteLink: ''
};
