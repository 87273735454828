import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import ServiceAPI from '../services';
import GlobalContext from '../context/globalContext';
import ThanksOrAlreadyVoteQr from '../components/QrPages/ThanksOrAlreadyVoteQr';
import FewLinksSuccessVoteQr from '../components/QrPages/FewLinksSuccessVoteQr';
import QrFirmLogo from '../components/QrPages/QrFirmLogo';
import QrFooter from '../components/QrPages/QrFooter';
import QrQuestions from '../components/QrPages/QrQuestions';

const opinionConst = {
  bad: 'button_3',
  neutral: 'button_2',
  like: 'button_1'
};

const reverceOpinionConst = {
  button_3: 'bad',
  button_2: 'neutral',
  button_1: 'like'
};

const developedIn = {
  uk: 'Розроблено в',
  ru: 'Разработано в',
  pl: 'Zaprojektowany w',
  en: 'Developed in'
};

const QrVotePage = () => {
  const service = new ServiceAPI();
  const { uuid } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { stateGlobal } = useContext(GlobalContext);
  const MinutesToAnotherVote = stateGlobal.isTestServer ? 0 : 4320; // 72 hours in production

  const [alreadyVote, setAlreadyVote] = useState(null);
  const [successVote, setSuccessVote] = useState(null);
  const [question, setQuestion] = useState('');
  const [image, setImage] = useState({
    logo: '',
    name: '',
    website_url: ''
  });
  const [additionalQuestions, setAdditionalQuestions] = useState({
    like: false,
    neutral: false,
    bad: false
  });
  const [showAdditionalQuestion, setShowAdditionalQuestion] = useState(false);
  const [additionalQuestionData, setAdditionalQuestionData] = useState([]);
  const [voteRedirectLinks, setVoteRedirectLinks] = useState({});
  const [moreThanOneLikeLinks, setMoreThanOneLikeLinks] = useState([]);
  const [moreThanOneLikeLinksSuccessVote, setMoreThanOneLikeLinksSuccessVote] = useState(false);
  const [requestId, setRequestId] = useState('');

  const [language, setLanguage] = useState('en');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const userVotes = JSON.parse(localStorage.getItem('userVotes'));

  const setAdditionalQuestionsFunc = (data) => {
    const newAdditionalQuestions = {};
    data.forEach((item) => {
      if (item.settings?.conditions.length) {
        item.settings.conditions.forEach((condition) => {
          const key = reverceOpinionConst[condition];
          newAdditionalQuestions[key] = true;
        });
      }
    });
    if (Object.keys(newAdditionalQuestions)) {
      newAdditionalQuestions.data = data;
      setAdditionalQuestions(newAdditionalQuestions);
    }
  };

  useEffect(() => {
    if (location?.search && !location?.search.includes('fbclid')) {
      setRequestId(location.search.split('=')[1]);
    };
  }, [])

  useEffect(() => {
    // check if user already vote
    if (userVotes && userVotes[uuid]) {
      moment().diff(moment(userVotes[uuid]), 'm') <= MinutesToAnotherVote
        ? setAlreadyVote(true)
        : setAlreadyVote(false);
    } else {
      setAlreadyVote(false);
    }
    // get question
    service.getQrVote(uuid).then((resp) => {
      if (resp.status === 200) {
        setQuestion(resp.data.questions?.[0]?.body || '');
        const device = resp.data?.devices[0];
        if (device && (device?.like || device?.neutral || device?.unlike)) {
          const links = {};
          if (device?.like && device?.like.length) {
            links.like = device?.like[0]?.url;
            if (device?.like.length > 1) setMoreThanOneLikeLinks(device?.like)
          }
          if (device?.neutral && device?.neutral.length) links.neutral = device?.neutral[0]?.url;
          if (device?.unlike && device?.unlike.length) links.unlike = device?.unlike[0]?.url;
          setVoteRedirectLinks(links);
        }
        resp.data?.questions[0]?.polls &&
          setAdditionalQuestionsFunc(resp.data.questions[0].polls);
        if (
          process.env.REACT_APP_SERVER === 'http://45.94.158.249:8000' &&
          resp.data?.firm?.logo
        ) {
          const url = new URL(resp.data?.firm?.logo);
          url.port = 8000;
          setImage({ ...resp.data?.firm, logo: url.href });
        } else {
          setImage(resp.data?.firm);
        }
      } else {
        history.push('/errorPage');
      }
    });

    // get browser language
    const userBrowserLang = navigator.language || navigator.userLanguage;
    const browserLang = userBrowserLang.split('-')[0];

    const notDefaultLangInSystem = ['uk', 'ru', 'pl', 'es'];

    if (notDefaultLangInSystem.includes(browserLang)) {
      // cheking if user browser is ru but timezone is uk
      if (browserLang === 'ru' && 
        (timezone === 'Europe/Kyiv' || timezone === 'Europe/Kiev')) {
        setLanguage('uk');  
      } else setLanguage(browserLang);
    }
  }, []);

  const userVotesUpdate = () => {
    // if user vote first time
    if (!userVotes) {
      localStorage.setItem('userVotes', JSON.stringify({ [uuid]: moment() }));
    }
    // not first time
    if (userVotes) {
      userVotes[uuid] = moment();
      localStorage.setItem('userVotes', JSON.stringify(userVotes));
    }
  };

  const getAdditionalQuestionsData = (button) => {
    if (button && additionalQuestions.data) {
      const filteredAdditionalQuestionData = additionalQuestions?.data.filter(
        (item) => item.settings?.conditions.includes(button)
      );
      setAdditionalQuestionData(filteredAdditionalQuestionData);
    }
  };

  const buttonHasLink = (opinion) => {
    return (
      (opinion === 'button_1' && voteRedirectLinks?.like) ||
      (opinion === 'button_2' && voteRedirectLinks?.neutral) ||
      (opinion === 'button_3' && voteRedirectLinks?.unlike)
    );
  };

  const redirectHandler = (opinion) => {
    if (opinion === 'button_1' && voteRedirectLinks?.like) {
      if (!moreThanOneLikeLinks.length) {
        window.location.replace(voteRedirectLinks?.like);
      } else {
        setMoreThanOneLikeLinksSuccessVote(true);
        userVotesUpdate();
      }
    } else if (opinion === 'button_2' && voteRedirectLinks?.neutral)
      window.location.replace(voteRedirectLinks?.neutral);
    else if (opinion === 'button_3' && voteRedirectLinks?.unlike)
      window.location.replace(voteRedirectLinks?.unlike);
  };

  const voteHandler = (opinion) => {
    const dataForBack = requestId
      ? { qr: uuid, [opinion]: 1, request_id: requestId }
      : { qr: uuid, [opinion]: 1 };
    service.qrVote(dataForBack).then((resp) => {
      if (resp.status === 201) {
        if (buttonHasLink(opinion)) {
          userVotesUpdate();
          return redirectHandler(opinion);
        }
        if (additionalQuestions[reverceOpinionConst[opinion]]) {
          getAdditionalQuestionsData(
            opinionConst[reverceOpinionConst[opinion]]
          );
          setShowAdditionalQuestion(true);
          userVotesUpdate();
        } else {
          setSuccessVote(true);
          userVotesUpdate();
        }
      } else {
        history.push('/errorPage');
      }
    });
  };

  const additionalQuestionsSubmitHandler = (dataForBack) => {
    if (requestId) dataForBack = { ...dataForBack, request_id: requestId };
    service.additionalQrVote(dataForBack).then((resp) => {
      if (resp.status === 201) {
        setSuccessVote(true);
      } else {
        history.push('/errorPage');
      }
    });
  };

  return (
    <div className="page__inner">
      <div className="page-qr">
        {alreadyVote && (
          <ThanksOrAlreadyVoteQr alreadyVote lang={language} image={image} />
        )}
        {!alreadyVote && !successVote && showAdditionalQuestion && (
          <QrQuestions
            data={additionalQuestionData[0]?.poll_questions}
            title={additionalQuestionData[0]?.title}
            onSubmitFunc={additionalQuestionsSubmitHandler}
            developedIn={developedIn[language]}
            lang={language}
          />
        )}
        {moreThanOneLikeLinksSuccessVote && (
          <FewLinksSuccessVoteQr 
            links={moreThanOneLikeLinks}
            image={image}
            developedIn={developedIn[language]}
            lang={language}
          />
        )}
        {successVote && <ThanksOrAlreadyVoteQr lang={language} image={image} />}
        {!alreadyVote &&
          alreadyVote !== null &&
          !moreThanOneLikeLinksSuccessVote &&
          !successVote &&
          !showAdditionalQuestion && (
            <>
              <div className="container">
                <QrFirmLogo image={image} />
                <div className="page-qr__rate">
                  <div className="page-qr__rate-question">{question}</div>
                  <div className="page-qr__rate-buttons">
                    <button
                      type="button"
                      className="qr-btn qr-btn--bad"
                      onClick={() => {
                        voteHandler(opinionConst.bad);
                      }}
                    >
                      Bad
                    </button>
                    <button
                      type="button"
                      className="qr-btn qr-btn--neutral"
                      onClick={() => {
                        voteHandler(opinionConst.neutral);
                      }}
                    >
                      Neutral
                    </button>
                    <button
                      type="button"
                      className="qr-btn qr-btn--good"
                      onClick={() => {
                        voteHandler(opinionConst.like);
                      }}
                    >
                      Good
                    </button>
                  </div>
                </div>
              </div>
              <QrFooter developedIn={developedIn[language]} />
            </>
          )}
      </div>
    </div>
  );
};

export default QrVotePage;
