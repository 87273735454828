import React from 'react';
import Reports from '../components/Reports/Reports';
import DefaultInner from './DefaultInner';

const ReportsPage = () => {
  return (
    <DefaultInner>
      <Reports />
    </DefaultInner>
  );
};

export default ReportsPage;
