import React, { useState, useContext, useRef } from 'react';
import GlobalContext from '../../context/globalContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import UserMenuPopUpWindow from './UserMenuPopUpWindow';
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage';
import { ReactComponent as IconMenu } from '../../img/sprite/svg/menu.svg';
import { ReactComponent as IconDefaultUser } from '../../img/sprite/svg/user_default.svg';

const UserInitials = (user) => {
  if (user.last_name && user.first_name) {
    return `${user.last_name.charAt(0).toUpperCase()}${user.first_name
      .charAt(0)
      .toUpperCase()}`;
  }
  return false;
};

const UserMenuPopUp = () => {
  const { stateGlobal } = useContext(GlobalContext);
  const { user } = stateGlobal;
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const userInitials = UserInitials(user);
  const openWindow = useRef();
  useOutsideClick(openWindow, () => setSettingsIsOpen(false));

  return (
    <>
      <div className="col d-flex justify-content-end">
        <div className="d-flex" ref={openWindow}>
          <ChangeLanguage />

          <div className="login">
            <div className="login__container">
              <span className="login__avatar user-avatar">
                {userInitials || <IconDefaultUser fill="#B2C4D5" />}
              </span>
              <button
                type="button"
                className="login__btn"
                onClick={() => setSettingsIsOpen(!settingsIsOpen)}
              >
                <IconMenu className="svg-sprite-icon icon-menu" />
              </button>
            </div>
          </div>
          {settingsIsOpen && (
            <UserMenuPopUpWindow
              setSettingsIsOpen={setSettingsIsOpen}
              userInitials={userInitials}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserMenuPopUp;
