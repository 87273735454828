import * as types from '../constants/actionsTypes';

// Login
export const login = (value) => ({
  type: types.LOGIN,
  payload: value
});

export const loginEmail = (value) => ({
  type: types.LOGIN_EMAIL,
  payload: value
});

export const loginPassword = (value) => ({
  type: types.LOGIN_PASSWORD,
  payload: value
});

export const loginRememberMe = (value) => ({
  type: types.LOGIN_REMEMBER_ME,
  payload: value
});

export const loginError = (value) => ({
  type: types.LOGIN_ERRORS,
  payload: value
});

// Logout
export const logOut = () => ({
  type: types.LOGOUT
});

// Loading
export const loading = (value) => ({
  type: types.LOADING,
  payload: value
});

// Change user info
export const changeUserInfo = (value) => ({
  type: types.CHANGE_USER_INFO,
  payload: value
});

export const firstName = (value) => ({
  type: types.FIRST_NAME,
  payload: value
});

export const lastName = (value) => ({
  type: types.LAST_NAME,
  payload: value
});

export const email = (value) => ({
  type: types.EMAIL,
  payload: value
});

export const brandName = (value) => ({
  type: types.BRAND_NAME,
  payload: value
});

export const oldPassword = (value) => ({
  type: types.OLD_PASSWORD,
  payload: value
});

export const newPassword = (value) => ({
  type: types.NEW_PASSWORD,
  payload: value
});

export const repeatNewPassword = (value) => ({
  type: types.REPEAT_NEW_PASSWORD,
  payload: value
});

export const error = (value) => ({
  type: types.ERROR,
  payload: value
});

// Change device info
export const changeDeviceInfo = (value) => ({
  type: types.CHANGE_DEVICE_INFO,
  payload: value
});

export const deviceName = (value) => ({
  type: types.DEVICE_NAME,
  payload: value
});

export const deviceId = (value) => ({
  type: types.DEVICE_ID,
  payload: value
});

export const deviceAddress = (value) => ({
  type: types.DEVICE_ADDRESS,
  payload: value
});

export const deviceError = (value) => ({
  type: types.DEVICE_ERROR,
  payload: value
});

// Polls
export const pollName = (value) => ({
  type: types.POLL_NAME,
  payload: value
});

export const questionName = (value) => ({
  type: types.QUESTION_NAME,
  payload: value
});

export const polldevices = (value) => ({
  type: types.DEVICES,
  payload: value
});

export const initialPollDevices = (value) => ({
  type: types.INITIAL_DEVICES,
  payload: value
});

export const pollsError = (value) => ({
  type: types.POLLS_ERRORS,
  payload: value
});

export const pollTouched = () => ({
  type: types.POLL_TOUCHED
});

export const pollEdit = (value) => ({
  type: types.POLL_EDIT,
  payload: value
});

// Reports
export const reportName = (value) => ({
  type: types.REPORT_NAME,
  payload: value
});

export const reportPoll = (value) => {
  return {
    type: types.REPORT_POLL,
    payload: value
  };
};

export const reportEmails = (value) => {
  return {
    type: types.REPORT_EMAILS,
    payload: value
  };
};

export const reportErrors = (value) => ({
  type: types.REPORT_ERRORS,
  payload: value
});

export const reportTouched = () => ({
  type: types.REPORT_TOUCHED
});

export const reportEdit = (value) => ({
  type: types.REPORT_EDIT,
  payload: value
});

// help desk
export const helpDesk = (value) => ({
  type: types.HELP_DESK,
  payload: value
});

export const helpText = (value) => ({
  type: types.CHANGE_HELP_TEXT,
  payload: value
});

export const helpError = (value) => ({
  type: types.HELP_DESK_ERROR,
  payload: value
});

export const helpDeskDefault = () => ({
  type: types.HELP_DESK_DEFAULT
});
