import {
  CHANGE_USER_INFO,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  BRAND_NAME,
  OLD_PASSWORD,
  NEW_PASSWORD,
  REPEAT_NEW_PASSWORD,
  ERROR
} from '../constants/actionsTypes';

export const initialState = {
  firstName: { value: '', touched: false },
  lastName: { value: '', touched: false },
  email: { value: '', touched: false },
  brandName: { value: '', touched: false },
  isOwner: null,
  isAdmin: null,
  oldPassword: { value: '', touched: false },
  newPassword: { value: '', touched: false },
  repeatNewPassword: { value: '', touched: false },
  errors: {
    requiredFirstName: false,
    requiredLastName: false,
    requiredEmail: false,
    emailPattern: false,
    requiredBrandName: false,
    requiredOldPassword: false,
    requiredNewPassword: false,
    requiredRepeatNewPassword: false,
    notMath: false,
    lengthNewPassword: false,
    badOldPassword: false,
    atLeastOneLowerUpperDigit: false,
    onlyLatinLetters: false,
    passwordToCommon: false,
    newEqualOldPassword: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_USER_INFO:
      return {
        ...state,
        firstName: { ...state.firstName, value: action.payload.first_name },
        lastName: { ...state.lastName, value: action.payload.last_name },
        email: { ...state.email, value: action.payload.email },
        brandName: { ...state.brandName, value: action.payload.brand_name },
        isOwner: action.payload.is_owner,
        isAdmin: action.payload.is_admin
      };
    case FIRST_NAME:
      return { ...state, firstName: { value: action.payload, touched: true } };
    case LAST_NAME:
      return { ...state, lastName: { value: action.payload, touched: true } };
    case EMAIL:
      return { ...state, email: { value: action.payload, touched: true } };
    case BRAND_NAME:
      return { ...state, brandName: { value: action.payload, touched: true } };
    case OLD_PASSWORD:
      return {
        ...state,
        oldPassword: { value: action.payload, touched: true }
      };
    case NEW_PASSWORD:
      return {
        ...state,
        newPassword: { value: action.payload, touched: true }
      };
    case REPEAT_NEW_PASSWORD:
      return {
        ...state,
        repeatNewPassword: { value: action.payload, touched: true }
      };
    case ERROR:
      return { ...state, errors: { ...state.errors, ...action.payload } };
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
