import React from 'react';
import PropTypes from 'prop-types';

const LoginPagesContainer = ({ child1, child2 }) => {
  return (
    <div className="page__inner">
      <div className="page-login">
        <div className="container">
          <div className="page-login__form">
            <div className="login-form">
              <div className="login-form__container">{child1}</div>
            </div>
          </div>
          <div className="page-login__form">
            {child2}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPagesContainer;

LoginPagesContainer.propTypes = {
  child1: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  child2: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
