import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';

const xLabel = {
  h: 'Hours',
  d: 'Days',
  w: 'Weeks',
  m: 'Months',
  s: 'Quarters',
  y: 'Years'
};

const ChartLineBig = ({
  lineData,
  lineColor,
  showGraphic,
  otherRanges,
  title,
  trunc
}) => {
  const { t } = useTranslation();

  const graphData = {
    labels: lineData.map((item) => item.date),
    datasets: [
      {
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        fill: false,
        lineTension: 0.1,
        legend: false,
        borderColor: lineColor,
        pointRadius: 2,
        data: lineData.map((item) => item.data),
        like: otherRanges.like,
        neutral: otherRanges.neutral,
        unlike: otherRanges.unlike
      }
    ]
  };
  const options = {
    responsive: true,
    aspectRatio: 4,
    legend: false,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            padding: 10,
            fontStyle: 'bold',
            fontColor: '#485766',
            lineHeight: 2,
            labelString: xLabel[trunc]
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            padding: 10,
            fontStyle: 'bold',
            fontColor: '#485766',
            lineHeight: 2,
            labelString: title
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      backgroundColor: '#fff',
      titleFontSize: 14,
      titleFontColor: '#485766',
      titleMarginBottom: 10,
      bodyFontColor: '#485766',
      footerFontColor: '#485766',
      footerFontStyle: 'normal',
      footerMarginTop: 16,
      footerSpacing: 6,
      xPadding: 15,
      yPadding: 15,
      cornerRadius: 3,
      displayColors: false,
      borderColor: '#e3e7ee',
      borderWidth: 1,
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          return label;
        },
        footer: function (tooltipItem, data) {
          const { index } = tooltipItem[0];
          return `${title}: ${data.datasets[0].data[index]}\n${t(
            'ratingPage.notHappy'
          )}: ${data.datasets[0].unlike[index]}\n${t('ratingPage.neutral')}: ${
            data.datasets[0].neutral[index]
          }\n${t('ratingPage.happy')}: ${data.datasets[0].like[index]}`;
        }
      }
    }
  };
  return (
    <Line data={graphData} options={options} onElementsClick={showGraphic} />
  );
};

export default ChartLineBig;

ChartLineBig.propTypes = {
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      data: PropTypes.number
    })
  ),
  lineColor: PropTypes.string,
  showGraphic: PropTypes.func,
  otherRanges: PropTypes.shape({}),
  title: PropTypes.string,
  trunc: PropTypes.string
};

ChartLineBig.defaultProps = {
  lineData: null,
  lineColor: '',
  showGraphic: null,
  otherRanges: null,
  title: '',
  trunc: ''
};
