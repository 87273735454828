import React from 'react';
import logoBase from '../../img/qr-logo-base.png';

const QrFirmLogo = ({ image }) => {
  if (image.logo && image.website_url) {
    return (
      <div className="page-qr__logo">
        <a href={image.website_url} target="_blank" rel="noreferrer">
          <img src={image.logo} alt={image.name} />
        </a>
      </div>
    );
  }
  if (image.logo && !image.website_url) {
    return (
      <div className="page-qr__logo">
        <img src={image.logo} alt={image.name} />
      </div>
    );
  }
  if (image.logo === null) {
    return (
      <div className="page-qr__logo">
        <a href="https://liketo.me/" target="_blank" rel="noreferrer">
          <img src={logoBase} alt="liketo.me" />
        </a>
      </div>
    );
  }
  return null;
};

export default QrFirmLogo;
