import React from 'react';
import PropTypes from 'prop-types';
import logoSended from '../../img/qr-sended.png';
import QrFirmLogo from './QrFirmLogo';
import QrFooter from './QrFooter';
import translation from './translation.json'

const ThanksOrAlreadyVoteQr = ({ alreadyVote, lang, image }) => {
  const message = alreadyVote
    ? translation.alreadyVote[lang]
    : translation.successVote[lang];
  return (
    <>
      <div className="container text-center">
        <QrFirmLogo image={image} />
        <img className="page-qr__sended-img" src={logoSended} alt="" />
        <h1 className="page-qr__sended-title">{translation.thanks[lang]}</h1>
        <p className="page-qr__sended-subtitle">
          {message}
          <br />
          {translation.haveANiceDay[lang]}
        </p>
      </div>
      <QrFooter developedIn={translation.developedIn[lang]} />
    </>
  );
};

export default ThanksOrAlreadyVoteQr;

ThanksOrAlreadyVoteQr.propTypes = {
  alreadyVote: PropTypes.bool,
  lang: PropTypes.string,
  image: PropTypes.shape({})
};

ThanksOrAlreadyVoteQr.defaultProps = {
  alreadyVote: false,
  lang: 'en',
  image: null
};
