import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ChartLineBig from '../Charts/ChartLineBig';

const GraphicModal = ({ show, onHide, range, type, otherRanges, trunc }) => {
  const { t } = useTranslation();

  const title = type === 'answers' ? t('statisticPage.answers') : type;

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <div className="modal-header text-center">
        <h5 className="modal-title w-100">
          <span>{title}</span>
        </h5>
        <button
          className="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => onHide()}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <ChartLineBig
        lineData={range}
        lineColor={
          type === 'CSAT' ? '#CB4D5F' : type === 'NPS' ? '#F4780F' : '#74ADE6'
        }
        type={type}
        otherRanges={otherRanges}
        title={title}
        trunc={trunc}
      />
    </Modal>
  );
};

export default GraphicModal;

GraphicModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  range: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  otherRanges: PropTypes.shape({}),
  trunc: PropTypes.string

};

GraphicModal.defaultProps = {
  show: false,
  onHide: null,
  range: null,
  type: '',
  otherRanges: null,
  trunc: ''
};
