import {
  REPORT_NAME,
  REPORT_POLL,
  REPORT_EMAILS,
  REPORT_ERRORS,
  REPORT_TOUCHED,
  REPORT_EDIT
} from '../constants/actionsTypes';

export const initialState = {
  reportName: { value: '', touched: false },
  reportPoll: { value: {}, touched: false },
  reportEmails: { value: [], touched: false },
  reportErrors: {
    requiredReportName: false,
    reportNameLength: false,
    requiredReportPoll: false,
    requiredReportEmails: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case REPORT_EDIT:
      return {
        ...state,
        reportName: { ...state.reportName, value: action.payload.reportName },
        reportPoll: {
          ...state.reportPoll,
          value: action.payload.reportPoll
        },
        reportEmails: {
          ...state.reportEmails,
          value: action.payload.reportEmails
        }
      };
    case REPORT_TOUCHED:
      return {
        ...state,
        reportName: { ...state.reportName, touched: true },
        reportPoll: { ...state.reportPoll, touched: true },
        reportEmails: { ...state.reportEmails, touched: true }
      };
    case REPORT_NAME:
      return { ...state, reportName: { value: action.payload, touched: true } };
    case REPORT_POLL:
      return {
        ...state,
        reportPoll: { value: action.payload, touched: true }
      };
    case REPORT_EMAILS:
      return {
        ...state,
        reportEmails: { value: action.payload, touched: true }
      };
    case REPORT_ERRORS:
      return {
        ...state,
        reportErrors: { ...state.reportErrors, ...action.payload }
      };
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
