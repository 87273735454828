import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconMinusCircle } from '../../img/sprite/svg/minus-circle.svg';

const DeleteButton = ({ text, openModal }) => {
  return (
    <div className="content__header-link">
      <a
        className="btn-add js-delete-item"
        role="button"
        onClick={() => openModal()}
      >
        <span>
          {text}
          <IconMinusCircle className="svg-sprite-icon icon-minus-circle" />
        </span>
      </a>
    </div>
  );
};

export default DeleteButton;

DeleteButton.propTypes = {
  text: PropTypes.string,
  openModal: PropTypes.func
};

DeleteButton.defaultProps = {
  text: '',
  openModal: null
};
