import { useState, useEffect } from 'react';
import moment from 'moment';

const useDates = () => {
  const datesSelect = JSON.parse(localStorage.getItem('filters'))?.datesSelect;
  const from = moment(JSON.parse(localStorage.getItem('filters'))?.from);
  const to = moment(JSON.parse(localStorage.getItem('filters'))?.to);
  const setProperTimeInFilters = () => {
    if (from) from.set({ hour: 0, minute: 0, second: 0 });
    if (to) to.set({ hour: 23, minute: 59, second: 59 });
  };
  setProperTimeInFilters();
 
  const [dates, setDates] = useState({
    from: from || moment(),
    to: to || moment(),
    datesSelect:
      datesSelect && moment().diff(datesSelect.time, 'days') === 0
        ? { date: datesSelect.date, time: datesSelect.time }
        : datesSelect && moment().diff(datesSelect.time, 'days') > 0
        ? { date: 'manual', time: moment() }
        : { date: 'today', time: moment() }
  });

  useEffect(() => {
    const oldFilters = JSON.parse(localStorage.getItem('filters'));
    if (oldFilters) {
      const from = dates.from;
      const to = dates.to;
      from.set({ hour: 0, minute: 0, second: 0 });
      to.set({ hour: 23, minute: 59, second: 59 });
      const newFilters = {
        ...oldFilters,
        from,
        to,
        datesSelect: dates.datesSelect
      };
      localStorage.setItem('filters', JSON.stringify(newFilters));
    } else {
      localStorage.setItem('filters', JSON.stringify(dates));
    }
  }, [dates]);

  const datesSetter = (query) => {
    if (query === 'last7Days') {
      setDates({
        from: moment().subtract(7, 'd'),
        to: moment(),
        datesSelect: { date: 'last7Days', time: moment() }
      });
    } else if (query === 'yestoday') {
      setDates({
        from: moment().subtract(1, 'd'),
        to: moment().subtract(1, 'd'),
        datesSelect: { date: 'yestoday', time: moment() }
      });
    } else if (query === 'last14Days') {
      setDates({
        from: moment().subtract(14, 'd'),
        to: moment(),
        datesSelect: { date: 'last14Days', time: moment() }
      });
    } else if (query === 'last30Days') {
      setDates({
        from: moment().subtract(30, 'd'),
        to: moment(),
        datesSelect: { date: 'last30Days', time: moment() }
      });
    } else if (query === 'thisWeek') {
      setDates({
        from: moment().startOf('isoWeek'),
        to: moment(),
        datesSelect: { date: 'thisWeek', time: moment() }
      });
    } else if (query === 'lastWeek') {
      setDates({
        from: moment().subtract(7, 'd').startOf('isoWeek'),
        to: moment().subtract(7, 'd').endOf('isoWeek'),
        datesSelect: { date: 'lastWeek', time: moment() }
      });
    } else if (query === 'thisMonth') {
      setDates({
        from: moment().startOf('month'),
        to: moment(),
        datesSelect: { date: 'thisMonth', time: moment() }
      });
    } else if (query === 'lastMonth') {
      setDates({
        from: moment().subtract(1, 'months').startOf('month'),
        to: moment().subtract(1, 'months').endOf('month'),
        datesSelect: { date: 'lastMonth', time: moment() }
      });
    } else {
      setDates({
        from: moment(),
        to: moment(),
        datesSelect: { date: 'today', time: moment() }
      });
    }
  };
  return { dates, setDates, datesSetter };
};

export default useDates;
