import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../components/StyleComponents/PageContent';
import connection from '../img/app-connection.png';
import DefaultInner from './DefaultInner';

const ServerErrorPage = () => {
  const { t } = useTranslation();

  return (
    <DefaultInner>
      <PageContent>
        <div className="content__header">
          {/* <h1 className="content__title"></h1> */}
        </div>
        <div className="statistics-start">
          <div className="statistics-start__container">
            <img src={connection} alt="" />
            <h2 className="statistics-start__title">
              {t('ServerErrorPage.pageText')}
            </h2>
          </div>
        </div>
      </PageContent>
    </DefaultInner>
  );
};

export default ServerErrorPage;
