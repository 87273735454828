import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as IconPlusCircle } from '../../img/sprite/svg/plus-circle.svg';

const AddButton = ({ text, linkTo }) => {
  return (
    <div className="content__header-link">
      <Link className="btn-add" to={linkTo}>
        <span>
          {text}
          <IconPlusCircle className="svg-sprite-icon icon-plus-circle" />
        </span>
      </Link>
    </div>
  );
};

export default AddButton;

AddButton.propTypes = {
  text: PropTypes.string,
  linkTo: PropTypes.string
};

AddButton.defaultProps = {
  text: '',
  linkTo: ''
};
