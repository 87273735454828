import React from 'react';
import Users from '../components/Users/Users';
import { PageContent } from '../components/StyleComponents';
import DefaultInner from './DefaultInner';

const UsersPage = () => {
  return (
    <DefaultInner>
      <PageContent>
        <Users />
      </PageContent>
    </DefaultInner>
  );
};

export default UsersPage;
