import {
  CHANGE_HELP_TEXT,
  HELP_DESK_ERROR,
  HELP_DESK_DEFAULT
} from '../constants/actionsTypes';

export const initialState = {
  helpText: { value: '', touched: false },
  helpErrors: {
    requiredText: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_HELP_TEXT:
      return { ...state, helpText: { value: action.payload, touched: true } };
    case HELP_DESK_ERROR:
      return {
        ...state,
        helpErrors: { ...state.deviceErrors, ...action.payload }
      };
    case HELP_DESK_DEFAULT:
      return initialState;
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
