import {
  CHANGE_DEVICE_INFO,
  DEVICE_NAME,
  DEVICE_ID,
  DEVICE_ADDRESS,
  DEVICE_ERROR
} from '../constants/actionsTypes';

export const initialState = {
  deviceName: { value: '', touched: false },
  deviceId: { value: '', touched: false },
  kind: { value: '', touched: false },
  deviceAddress: { value: '', touched: false },
  deviceErrors: {
    requiredDeviceName: false,
    requiredDeviceId: false,
    requiredDeviceAddress: false,
    lengthDeviceName: false,
    lengthDeviceId: false,
    lengthDeviceAddress: false
  }
};

// eslint-disable-next-line
const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_DEVICE_INFO:
      return {
        ...state,
        deviceName: { ...state.deviceName, value: action.payload.name },
        deviceId: { ...state.deviceId, value: action.payload.id },
        kind: { ...state.kind, value: action.payload.kind },
        deviceAddress: { ...state.deviceAddress, value: action.payload.address }
      };
    case DEVICE_NAME:
      return { ...state, deviceName: { value: action.payload, touched: true } };
    case DEVICE_ID:
      return { ...state, deviceId: { value: action.payload, touched: true } };
    case DEVICE_ADDRESS:
      return {
        ...state,
        deviceAddress: { value: action.payload, touched: true }
      };
    case DEVICE_ERROR:
      return {
        ...state,
        deviceErrors: { ...state.deviceErrors, ...action.payload }
      };
    default:
      console.log(`${action.type}`); // eslint-disable-line
  }
};

export default reducer;
