import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../hooks/useOutsideClick';

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  let currentLang = i18n.language;
  const showingLang = currentLang !== 'ukr' ? currentLang : 'ua'

  const [changeLangIsOpen, setChangeLangIsOpen] = useState(false);
  const openLangWindow = useRef();
  useOutsideClick(openLangWindow, () => setChangeLangIsOpen(false));


  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="col d-flex justify-content-end">
      <button
        type="button"
        className="lang__btn"
        onClick={() => setChangeLangIsOpen(!changeLangIsOpen)}
      >
        {showingLang.toUpperCase()}
      </button>
      {changeLangIsOpen && (
        <nav className="menu" ref={openLangWindow}>
          <ul className="menu__list-lang">
          <div className="lang d-flex align-items-center">
            <a
              className={currentLang === 'ukr' ? 'is-active' : undefined}
              role="button"
              onClick={(e) => {
                e.preventDefault();
                changeLang('ukr');
              }}
            >
              UA
            </a>
            <a
              className={currentLang === 'ru' ? 'is-active' : undefined}
              role="button"
              onClick={(e) => {
                e.preventDefault();
                changeLang('ru');
              }}
            >
              RU
            </a>
            <a
              className={currentLang === 'pl' ? 'is-active' : undefined}
              role="button"
              onClick={(e) => {
                e.preventDefault();
                changeLang('pl');
              }}
            >
              PL
            </a>
            <a
              className={currentLang === 'en' ? 'is-active' : undefined}
              role="button"
              onClick={(e) => {
                e.preventDefault();
                changeLang('en');
              }}
            >
              EN
            </a>
          </div>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default ChangeLanguage;
