import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import ServiceAPI from '../../services';
import EditButton from '../Buttons/EditButton';
import { saveAsFile } from '../../usefulFunctions';

const ReportRow = ({ report, userIsOwnerOrAdmin }) => {
  const { t } = useTranslation();
  const service = new ServiceAPI();

  const formatedDates = {
    startDate: moment(report.start_date).format('DD.MM.YYYY'),
    endDate: moment(report.end_date).format('DD.MM.YYYY'),
    prevStartDate: moment(report.prev_start_date).format('DD.MM.YYYY'),
    prevEndDate: moment(report.prev_end_date).format('DD.MM.YYYY')
  };

  const sendingFrequencys = {
    1: t('reportsPage.everyDay'),
    7: t('reportsPage.everyWeek'),
    month: t('reportsPage.everyMonth'),
    year: t('reportsPage.everyYear')
  };

  const exportReport = (id, templateName) => {
    service.exportReport(id).then((response) => {
      if (response.status === 200) {
        const contentTypeFile = response.header.get('content-type');

        response.reader.blob().then((blob) => {
          saveAsFile(blob, `${templateName}.xls`, contentTypeFile);
        });
      }
    });
  };

  return (
    <div className="reports-panel__body">
      <div className="col-2 reports-panel__body-device">
        {report.name || ''}
      </div>
      <div className="col-4 reports-panel__body-polls">
        {/* <h5>Гипермаркеты в городе Киев</h5> */}
        <p>{report.quiz?.title || ''}</p>
      </div>
      <div className="col-3 reports-panel__body-period">
        <time>{`${formatedDates.startDate} - ${formatedDates.endDate}`}</time>
        {report.prev_start_date && report.prev_end_date && (
          <time>{`${formatedDates.prevStartDate} - ${formatedDates.prevEndDate}`}</time>
        )}
        {/* <span>{sendingFrequencys[report.period_create]}</span> */}
      </div>
      <div className="col-2 reports-panel__body-download text-center">
        <a
          className={`btn-report ${
            !report.has_data || !userIsOwnerOrAdmin ? 'disabled' : ''
          }`}
          target="blank"
          role="button"
          onClick={(e) => {
            e.preventDefault();
            exportReport(report.id, report.name);
          }}
        >
          XLS
        </a>
      </div>
      <div className="col-1 reports-panel__body-edit text-center">
        {userIsOwnerOrAdmin && (
          <EditButton
            tooltipText={t('reportsPage.edit')}
            linkTo={`/reports/${report.id}`}
          />
        )}
      </div>
    </div>
  );
};

export default ReportRow;

ReportRow.propTypes = {
  report: PropTypes.shape({}),
  userIsOwnerOrAdmin: PropTypes.bool
};

ReportRow.defaultProps = {
  report: null,
  userIsOwnerOrAdmin: false
};
