import React, { useState, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ServiceAPI from '../../services';
import reducer, { initialState } from '../../reducers/helpDeskModal';
import GlobalContext from '../../context/globalContext';
import { helpDesk, helpText, helpError, helpDeskDefault } from '../../actions';
import { ReactComponent as IconHelp } from '../../img/sprite/svg/help.svg';
// import DefaultInner from '../../Layouts/DefaultInner';

const HelpModal = ({ show }) => {
  const { t } = useTranslation();
  const { dispatchGlobal } = useContext(GlobalContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const service = new ServiceAPI();

  const [successOrError, setSuccessOrError] = useState(null);

  const validateHelpText = (value) => {
    dispatch(
      helpError({
        requiredText: !value
      })
    );
  };

  const onHide = () => {
    dispatchGlobal(helpDesk(false));
    dispatch(helpDeskDefault());
    setTimeout(() => setSuccessOrError(null), 1000);
  };

  const validateFields = () => {
    validateHelpText(state.helpText.value);
    return !state.helpErrors.requiredText && state.helpText.value;
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    service.sendServiceEmail({ body: state.helpText.value }).then((resp) => {
      if (resp.status === 200) {
        setSuccessOrError('success');
      }
    });
  };

  return (
    <Modal show={show} onHide={() => onHide()} className="modal-help">
      <Modal.Header closeButton>
        <span className="modal-icon">
          <IconHelp />
        </span>
        <Modal.Title>
          {successOrError === 'success'
            ? t('HelpDesk.thanksForQuestion')
            : successOrError === 'error'
            ? t('HelpDesk.somethingNotRight')
            : t('HelpDesk.help')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successOrError === 'success' ? (
          <p>{t('HelpDesk.menegersConnect')}</p>
        ) : successOrError === 'error' ? (
          <p>{t('HelpDesk.sendForm')}</p>
        ) : (
          <form>
            <div className="form-group">
              <label htmlFor="helpText">{t('HelpDesk.ourTeamHere')}</label>
              <textarea
                className={
                  state.helpText.touched && !state.helpErrors.requiredText
                    ? 'form-control is-valid'
                    : state.helpErrors.requiredText
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                id="helpText"
                rows="6"
                placeholder={t('HelpDesk.enterQuestion')}
                value={state.helpText.value}
                onChange={(e) => {
                  validateHelpText(e.target.value);
                  dispatch(helpText(e.target.value));
                }}
              />
              {state.helpErrors.requiredText && (
                <div className="error">
                  <span>{t('ERROR_TEXT.required')}</span>
                </div>
              )}
            </div>
          </form>
        )}
      </Modal.Body>
      {!successOrError && (
        <Modal.Footer>
          <button
            className="btn btn-modal-cancel"
            type="button"
            onClick={() => onHide()}
          >
            {t('HelpDesk.cancel')}
          </button>
          <button
            className="btn btn-modal-confirm"
            type="button"
            onClick={handlerSubmit}
          >
            {t('HelpDesk.send')}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default HelpModal;

HelpModal.propTypes = {
  show: PropTypes.bool
};

HelpModal.defaultProps = {
  show: false
};
