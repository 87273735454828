import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as IconLeftArrow } from '../../img/sprite/svg/left-arrow.svg';

const PrevPage = ({ to, pageName }) => {
  return (
    <div className="content__breadcrumb-back">
      <Link to={to}>
        <IconLeftArrow className="svg-sprite-icon icon-left-arrow" />
        {pageName}
      </Link>
    </div>
  );
};

export default PrevPage;

PrevPage.propTypes = {
  to: PropTypes.string,
  pageName: PropTypes.string
};

PrevPage.defaultProps = {
  to: '',
  pageName: ''
};
