import React from 'react';
import Devices from '../components/Devices/Devices';
import DefaultInner from './DefaultInner';

const DevicesPage = () => {
  return (
    <DefaultInner>
      <Devices />
    </DefaultInner>
  );
};

export default DevicesPage;
