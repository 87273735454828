import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-lodash';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/globalContext';
import ServiceAPI from '../../services';
import DeviceRow from './DeviceRow';
import PageContent from '../StyleComponents/PageContent';
import AddButton from '../Buttons/AddButton';
import { ReactComponent as IconSort } from '../../img/sprite/svg/sort.svg';
import { ReactComponent as IconPlus } from '../../img/sprite/svg/plus.svg';
import usePagination from '../../hooks/usePagination';
import Pagination from '../Pagination/Pagination';
import Loading from '../Loading/Loading';
import { loading } from '../../actions';

const Devices = () => {
  const service = new ServiceAPI();
  const { t } = useTranslation();
  const { dispatchGlobal, stateGlobal } = useContext(GlobalContext);
  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const userIsOwnerOrAdmin =
    stateGlobal.user?.is_owner || stateGlobal.user?.is_admin;

  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, process.env.REACT_APP_PAGINATION_NUM);

  useEffect(() => {
    dispatchGlobal(loading(true));
    service.getDevices().then((resp) => {
      if (resp.status === 200) {
        setCount(resp.data.count);
        setDevices(resp.data.results);
        dispatchGlobal(loading(false));
      }
    });
  }, []);

  useEffect(() => {
    // Search
    if (searchText !== null) {
      const timeout = setTimeout(() => {
        dispatchGlobal(loading(true));
        const searchObj = searchText && { search: searchText };
        service.getDevices(searchObj).then((resp) => {
          if (resp.status === 200) {
            setCount(resp.data.count);
            setDevices(resp.data.results);
            setCurrentPage(1);
            dispatchGlobal(loading(false));
          }
        });
      }, 1000);

      // if this effect run again, because `inputValue` changed, we remove the previous timeout
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const changePage = (pageNum) => {
    if (currentPage !== pageNum) {
      dispatchGlobal(loading(true));
      const paginationObj = {
        limit: process.env.REACT_APP_PAGINATION_NUM,
        offset:
          pageNum * process.env.REACT_APP_PAGINATION_NUM -
          process.env.REACT_APP_PAGINATION_NUM
      };
      if (searchText) paginationObj.search = searchText;
      service.getDevices(paginationObj).then((resp) => {
        if (resp.status === 200) {
          setDevices(resp.data.results);
          dispatchGlobal(loading(false));
        }
      });
    }
  };

  return (
    <PageContent>
      <div className="content__header">
        <h1 className="content__title">{t('devicesPage.pageName')}</h1>
        {/* <AddButton text={t('devicesPage.addDevice')} linkTo="/" /> */}
      </div>
      <div className="content__search">
        <div className="page-search">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder={t('devicesPage.search')}
              value={searchText || ''}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="content__panels">
        <div className="devices">
          <div className="devices__panel">
            <div className="devices-panel">
              <div className="devices-panel__head">
                <div className="col-2 devices-panel__head-title text-center sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('devicesPage.name')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-2 devices-panel__head-id text-center sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('devicesPage.idNumber')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-5 devices-panel__head-location sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('devicesPage.address')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-2 devices-panel__head-status text-center sortable-link">
                  {/* <a className="sortable-link"> */}
                  {t('devicesPage.deviceType')}
                  {/* <IconSort className="svg-sprite-icon icon-sort" /> */}
                  {/* </a> */}
                </div>
                <div className="col-1 devices-panel__head-edit text-center" />
              </div>
              {stateGlobal.isLoading ? (
                <Loading />
              ) : (
                <Map
                  collection={devices}
                  iteratee={(item) => {
                    return (
                      <DeviceRow
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        deviceNumber={item.mac}
                        address={item.address}
                        kind={item.kind}
                        userIsOwnerOrAdmin={userIsOwnerOrAdmin}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="content__pagination content__pagination--has-btn">
        {/* <Link to="/" className="btn-panel-add">
          <span>
            {t('devicesPage.addDevice')}
            <span className="icon-btn">
              <IconPlus className="svg-sprite-icon icon-plus" />
            </span>
          </span>
        </Link> */}
        <Pagination
          currentPage={currentPage}
          maxPage={maxPage}
          next={next}
          prev={prev}
          jump={jump}
          changePage={changePage}
        />
      </div>
    </PageContent>
  );
};

export default Devices;
