import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ServiceAPI from '../../services';
import {
  changeUserInfo,
  firstName,
  lastName,
  email,
  brandName,
  oldPassword,
  newPassword,
  repeatNewPassword,
  error
} from '../../actions';
import {
  validateNameLength,
  validatePasswordLength,
  validateEmailPattern,
  validatePasswordAtLeastOneUpperLowerDigit,
  validatePasswordOnlyLatinLettersAndNumbers
} from '../../usefulFunctions';
import reducer, { initialState } from '../../reducers/changeUserInfo';
import GlobalContext from '../../context/globalContext';
import PageContent from '../StyleComponents/PageContent';
import MainButton from '../Buttons/MainButton';
import { ReactComponent as IconEye } from '../../img/sprite/svg/eye.svg';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';

const Account = () => {
  const service = new ServiceAPI();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stateGlobal, dispatchGlobal } = useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();
  const notify = () => toast(t('ChangesSaves.changesIsSaved'));
  const [visiblePassword, setVisiblePassword] = useState({
    oldPassword: false,
    newPassword: false,
    repeatNewPassword: false
  });
  
  useEffect(() => {
    service.getUserInfo().then((resp) => {
      if (resp.status === 200) {
        dispatch(changeUserInfo(resp.data));
      }
    });
    // eslint-disable-next-line
  }, []);

  const newPasswordMatch = (value, isNewPassword) => {
    if (isNewPassword) {
      return value === state.repeatNewPassword.value;
    }
    return value === state.newPassword.value;
  };

  const validateNewEqualOldPassword = (value) => {
    return value === state.oldPassword.value;
  };

  const validateFirstNameField = (value) => {
    dispatch(
      error({
        requiredFirstName: !value,
        lengthFirstName: !validateNameLength(value, 3, 254)
      })
    );
  };

  const validateLastNameField = (value) => {
    dispatch(
      error({
        requiredLastName: !value,
        lengthLastName: !validateNameLength(value, 3, 254)
      })
    );
  };

  const validateEmailField = (value) => {
    dispatch(
      error({
        requiredEmail: !value,
        emailPattern: !validateEmailPattern(value)
      })
    );
  };

  const validateBrandNameField = (value) => {
    dispatch(
      error({
        requiredBrandName: !value
      })
    );
  };

  const validateOldPasswordField = (value) => {
    dispatch(
      error({
        requiredOldPassword: !value
      })
    );
  };

  const validateNewPasswordField = (value) => {
    dispatch(
      error({
        requiredNewPassword: !value,
        lengthNewPassword: !validatePasswordLength(value, 8),
        atLeastOneLowerUpperDigit: !validatePasswordAtLeastOneUpperLowerDigit(
          value
        ),
        onlyLatinLetters: !validatePasswordOnlyLatinLettersAndNumbers(value),
        notMath: !newPasswordMatch(value, true),
        passwordToCommon: !value,
        newEqualOldPassword: validateNewEqualOldPassword(value)
      })
    );
  };
  const validateRepeatNewPasswordField = (value) => {
    dispatch(
      error({
        requiredRepeatNewPassword: !value,
        notMath: !newPasswordMatch(value, false),
        passwordToCommon: !value
      })
    );
  };

  const clearOldPasswordNotMatch = () => {
    dispatch(
      error({
        badOldPassword: false
      })
    );
  };

  const validateFields = () => {
    validateFirstNameField(state.firstName.value);
    validateLastNameField(state.lastName.value);
    validateEmailField(state.email.value);
    validateBrandNameField(state.brandName.value);
    return (
      !(
        state.errors.requiredFirstName ||
        state.errors.requiredLastName ||
        state.errors.requiredEmail ||
        state.errors.emailPattern ||
        state.errors.requiredBrandName
      ) &&
      state.firstName.value &&
      state.lastName.value &&
      state.email.value &&
      state.brandName.value
    );
  };

  const validatePasswordFields = () => {
    validateOldPasswordField(state.oldPassword.value);
    validateNewPasswordField(state.newPassword.value);
    validateRepeatNewPasswordField(state.repeatNewPassword.value);
    return (
      !(
        state.errors.requiredOldPassword ||
        state.errors.requiredNewPassword ||
        state.errors.requiredRepeatNewPassword ||
        state.errors.notMath ||
        state.errors.lengthNewPassword ||
        state.errors.newEqualOldPassword
      ) &&
      state.oldPassword.value &&
      state.newPassword.value &&
      state.repeatNewPassword.value
    );
  };

  const handlerOnChangeField = (e, func) => {
    dispatch(func(e.target.value));
  };

  const handleChangeUserInfo = () => {
    service
      .changeUserInfo({
        email: state.email.value,
        first_name: state.firstName.value,
        last_name: state.lastName.value,
        brand_name: state.brandName.value
      })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(
            changeUserInfo({
              first_name: resp.data.first_name,
              last_name: resp.data.last_name,
              email: resp.data.email,
              brand_name: resp.data.brand_name
            })
          );
          dispatchGlobal(
            changeUserInfo({
              ...stateGlobal.user,
              first_name: resp.data.first_name,
              last_name: resp.data.last_name,
              email: resp.data.email,
              brand_name: resp.data.brand_name
            })
          );
          notify();
          stateGlobal.user?.is_admin || stateGlobal.user?.is_owner
            ? history.push('/users')
            : history.push('/');
        }
      });
  };
  const handlerChangeUserPassword = () => {
    service
      .changeUserPassword({
        new_password: state.newPassword.value,
        confirm_password: state.repeatNewPassword.value,
        password: state.oldPassword.value
      })
      .then((resp) => {
        if (resp.status === 200) {
          handleChangeUserInfo();
        } else if (resp.status === 400) {
          if (
            resp.data?.password &&
            resp.data?.password[0] === 'Current password is not correct.'
          ) {
            dispatch(
              error({
                badOldPassword: true
              })
            );
          } else if (
            resp.data?.non_field_errors &&
            resp.data?.non_field_errors[0] === 'This password is too common.'
          ) {
            dispatch(
              error({
                passwordToCommon: true
              })
            );
          }
        }
      });
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    if (
      state.oldPassword.value ||
      state.newPassword.value ||
      state.repeatNewPassword.value
    ) {
      if (!validatePasswordFields()) return false;
      handlerChangeUserPassword();
    } else {
      handleChangeUserInfo();
    }
  };

  return (
    <>
      <div className="content__header">
        <h1 className="content__title">{t('Account.pageName')}</h1>
      </div>
      <div className="content__box">
        <div className="user-form">
          <div className="user-form__conatiner user-form__conatiner--profile">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlerSubmit();
              }}
            >
              <div className="user-form__info">
                <div className="form-group mb-4">
                  <label htmlFor="firstName">
                    <span className="required-label">*</span>
                    {t('Account.firstName')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.firstName.touched && !state.errors.requiredFirstName
                        ? 'form-control is-valid'
                        : state.firstName.touched &&
                          state.errors.requiredFirstName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={state.firstName.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, firstName);
                      validateFirstNameField(e.target.value);
                    }}
                  />
                  {state.errors.requiredFirstName && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.requiredFirstName')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lastName">
                    <span className="required-label">*</span>
                    {t('Account.lastName')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.lastName.touched && !state.errors.requiredLastName
                        ? 'form-control is-valid'
                        : state.lastName.touched &&
                          state.errors.requiredLastName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={state.lastName.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, lastName);
                      validateLastNameField(e.target.value);
                    }}
                  />
                  {state.errors.requiredLastName && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.requiredLastName')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="email">
                    <span className="required-label">*</span>
                    Email
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.email.touched &&
                      !state.errors.requiredEmail &&
                      !state.errors.emailPattern
                        ? 'form-control is-valid'
                        : state.email.touched &&
                          (state.errors.requiredEmail ||
                            state.errors.emailPattern)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="email"
                    type="text"
                    name="email"
                    value={state.email.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, email);
                      validateEmailField(e.target.value);
                    }}
                  />
                  {state.errors.requiredEmail && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.requiredEmail')}</span>
                    </div>
                  )}
                  {state.errors.emailPattern && !state.errors.requiredEmail && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.validEmail')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group mb-5">
                  <label htmlFor="company">
                    <span className="required-label">*</span>
                    {t('Account.company')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.brandName.touched && !state.errors.requiredBrandName
                        ? 'form-control is-valid'
                        : state.brandName.touched &&
                          state.errors.requiredBrandName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="company"
                    type="text"
                    name="company"
                    value={state.brandName.value || ''}
                    onChange={(e) => {
                      handlerOnChangeField(e, brandName);
                      validateBrandNameField(e.target.value);
                    }}
                  />
                  {state.errors.requiredBrandName && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.requiredBrandName')}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="user-form__change-password">
                <p className="user-form__change-password-title">
                  {t('Account.changePassword')}
                </p>
                <div className="form-group mb-4">
                  <label htmlFor="oldPassword">
                    {/* <span className="required-label">*</span> */}
                    {t('Account.oldPassword')}
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      autoComplete="off"
                      className={
                        state.oldPassword.touched &&
                        !state.errors.requiredOldPassword &&
                        !state.errors.badOldPassword
                          ? 'form-control is-valid'
                          : state.oldPassword.touched &&
                            (state.errors.requiredOldPassword ||
                              state.errors.badOldPassword)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="password"
                      type={visiblePassword.oldPassword ? 'text' : 'password'}
                      name="oldPassword"
                      value={state.oldPassword.value || ''}
                      onChange={(e) => {
                        handlerOnChangeField(e, oldPassword);
                        state.errors.badOldPassword &&
                          clearOldPasswordNotMatch();
                        validateOldPasswordField(e.target.value);
                      }}
                    />
                    <span
                      className={
                        visiblePassword.oldPassword
                          ? 'toggle-password toggle-password-visible'
                          : 'toggle-password'
                      }
                      toggle="#password"
                    >
                      <IconEye
                        className="svg-sprite-icon icon-eye"
                        onClick={() => {
                          setVisiblePassword({
                            ...visiblePassword,
                            oldPassword: !visiblePassword.oldPassword
                          });
                        }}
                      />
                    </span>
                  </div>
                  {state.errors.requiredOldPassword && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.oldPasswordRequired')}</span>
                    </div>
                  )}
                  {state.errors.badOldPassword &&
                    !state.errors.requiredOldPassword && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.oldPasswordNotMatch')}</span>
                      </div>
                    )}
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="newPassword">
                    {/* <span className="required-label">*</span> */}
                    {t('Account.newPassword')}
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      autoComplete="off"
                      className={
                        state.newPassword.touched &&
                        !state.errors.requiredNewPassword &&
                        !state.errors.lengthNewPassword &&
                        !state.errors.atLeastOneLowerUpperDigit &&
                        !state.errors.passwordToCommon &&
                        !state.errors.newEqualOldPassword
                          ? 'form-control is-valid'
                          : state.newPassword.touched &&
                            (state.errors.requiredNewPassword ||
                              state.errors.lengthNewPassword ||
                              state.errors.atLeastOneLowerUpperDigit ||
                              state.errors.passwordToCommon ||
                              state.errors.newEqualOldPassword)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="newPassword"
                      type={visiblePassword.newPassword ? 'text' : 'password'}
                      name="newPassword"
                      value={state.newPassword.value || ''}
                      onChange={(e) => {
                        handlerOnChangeField(e, newPassword);
                        validateNewPasswordField(e.target.value);
                      }}
                    />
                    <span
                      className={
                        visiblePassword.newPassword
                          ? 'toggle-password toggle-password-visible'
                          : 'toggle-password'
                      }
                      toggle="#password"
                    >
                      <IconEye
                        className="svg-sprite-icon icon-eye"
                        onClick={() => {
                          setVisiblePassword({
                            ...visiblePassword,
                            newPassword: !visiblePassword.newPassword
                          });
                        }}
                      />
                    </span>
                  </div>
                  {state.errors.requiredNewPassword && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.newPasswordRequired')}</span>
                    </div>
                  )}
                  {(state.errors.lengthNewPassword ||
                    state.errors.atLeastOneLowerUpperDigit) &&
                    !state.errors.requiredNewPassword && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.lengthPassword')}</span>
                      </div>
                    )}
                  {state.errors.newEqualOldPassword &&
                    !state.errors.lengthNewPassword &&
                    !state.errors.atLeastOneLowerUpperDigit &&
                    !state.errors.requiredNewPassword && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.oldEqualNewPassword')}</span>
                      </div>
                    )}
                </div>
                <div className="form-group mb-1">
                  <label htmlFor="repeatPassword">
                    {/* <span className="required-label">*</span> */}
                    {t('Account.repeatNewPassword')}
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      autoComplete="off"
                      className={
                        state.repeatNewPassword.touched &&
                        !state.errors.requiredRepeatNewPassword &&
                        !state.errors.notMath &&
                        !state.errors.passwordToCommon
                          ? 'form-control is-valid'
                          : state.repeatNewPassword.touched &&
                            (state.errors.requiredRepeatNewPassword ||
                              state.errors.notMath ||
                              state.errors.passwordToCommon)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      }
                      id="password-confirm"
                      type={
                        visiblePassword.repeatNewPassword ? 'text' : 'password'
                      }
                      name="repeatPassword"
                      value={state.repeatNewPassword.value || ''}
                      onChange={(e) => {
                        handlerOnChangeField(e, repeatNewPassword);
                        validateRepeatNewPasswordField(e.target.value);
                      }}
                    />
                    <span
                      className={
                        visiblePassword.repeatNewPassword
                          ? 'toggle-password toggle-password-visible'
                          : 'toggle-password'
                      }
                      toggle="#password-confirm"
                    >
                      <IconEye
                        className="svg-sprite-icon icon-eye"
                        onClick={() => {
                          setVisiblePassword({
                            ...visiblePassword,
                            repeatNewPassword: !visiblePassword.repeatNewPassword
                          });
                        }}
                      />
                    </span>
                  </div>
                  {state.errors.requiredRepeatNewPassword && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.repeatNewPassword')}</span>
                    </div>
                  )}
                  {state.errors.notMath &&
                    state.repeatNewPassword.touched &&
                    !state.errors.requiredRepeatNewPassword && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.passworsdNotMatch')}</span>
                      </div>
                    )}
                  {state.errors.passwordToCommon &&
                    state.repeatNewPassword.touched &&
                    !state.errors.notMath &&
                    !state.errors.requiredRepeatNewPassword && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.passwordToCommon')}</span>
                      </div>
                    )}
                </div>
                <div className="form-group mb-4">
                  <p className="mb-0">{t('Account.passwordRequirements')}</p>
                </div>
              </div>
              <div className="form-group has-submit-btn mb-0">
                <MainButton
                  type="submit"
                  className="btn btn-confirm has-icon"
                  text={t('Account.save')}
                >
                  <span className="icon-btn">
                    <IconConfirm className="svg-sprite-icon icon-confirm" />
                  </span>
                </MainButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
