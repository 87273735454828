import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import _ from 'underscore';
import percentRound from 'percent-round';
import ServiceAPI from '../../services';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import usePagination from '../../hooks/usePagination';
import useDates from '../../hooks/useDates';
import { ReactComponent as IconSort } from '../../img/sprite/svg/sort.svg';
import { ReactComponent as IconPhone } from '../../img/sprite/svg/phone.svg';
import { ReactComponent as IconComment } from '../../img/sprite/svg/comment.svg';
import { ReactComponent as IconPlusCircle } from '../../img/sprite/svg/plus-circle.svg';

const commentsPerPage = 5;

const AdditionalAnswersModal = ({ show, onHide, data }) => {
  const service = new ServiceAPI();
  const { t } = useTranslation();

  const [commentsPoll, setCommentsPoll] = useState([]);
  const [commentsAmount, setCommentsAmount] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [answersTitle, setAnswersTitle] = useState('');
  const [answersLoading, setAnswersLoading] = useState(false);
  const [percents, setPercents] = useState(0);

  const [createTimeOrdering, setCreateTimeOrdering] = useState(true);

  const [count, setCount] = useState(0);
  const {
    next,
    prev,
    jump,
    currentPage,
    setCurrentPage,
    maxPage
  } = usePagination(count, commentsPerPage);

  const { dates } = useDates();
  const dateTo = moment(dates?.to).utc().format().replace('Z', '');
  const dateFrom = moment(dates?.from).utc().format().replace('Z', '');

  const dataForBack = {
    quizId: data?.quizId,
    questionId: data?.questionId,
    pollId: data?.pollData?.id
  };

  const getOrdering = () => {
    return createTimeOrdering ? '-created_at' : 'created_at';
  };

  useEffect(() => {
    if (show) {
      setAnswersLoading(true);
      service
        .qrVoteQuestionAnswers({
          ...dataForBack,
          data: { created_at_before: dateTo, created_at_after: dateFrom }
        })
        .then((resp) => {
          if (resp.status === 200) {
            setAnswersTitle(resp.data.title);
            const backAnswers = resp.data.poll_questions.filter(
              (item) => item.type === 'bool'
            );
            setAnswers(backAnswers);
            setAnswersLoading(false);
          }
        });

      const backComments = data?.pollData?.poll_questions?.find(
        (item) => item.type === 'text'
      );
      if (backComments) {
        setCommentsPoll(backComments);
        setCommentsLoading(true);
        service
          .qrVoteQuestionComments({
            ...dataForBack,
            commentsId: backComments?.id,
            data: {
              ordering: getOrdering(),
              created_at_before: dateTo,
              created_at_after: dateFrom,
              limit: commentsPerPage
            }
          })
          .then((resp) => {
            if (resp.status === 200) {
              setCount(resp.data.count);
              setCommentsAmount(resp.data.count);
              setComments(resp.data.results);
              setCurrentPage(1);
              setCommentsLoading(false);
            }
          });
      }
    } else {
      setAnswersTitle('');
      setAnswers([]);
      setAnswersLoading(false);
      setCommentsAmount(0);
      setComments([]);
      setCommentsLoading(false);
      setCount(0);
      setCreateTimeOrdering(true);
    }
  }, [show]);

  useEffect(() => {
    if (answers.length > 0) {
      let total = 0;
      const allAnswers = sortBy(answers, ['position']).map((item) => {
        total += item.count_positive_answers;
        return item.count_positive_answers;
      });
      const allPercents = allAnswers.map((item) => {
        if (item === 0) return 0;
        return (item * 100) / total;
      });
      setPercents(percentRound(allPercents));
    }
  }, [answers]);

  const changePage = (pageNum) => {
    setCommentsLoading(true);
    if (currentPage !== pageNum) {
      service
        .qrVoteQuestionComments(
          pageNum > 1
            ? {
                ...dataForBack,
                commentsId: commentsPoll?.id,
                data: {
                  ordering: getOrdering(),
                  created_at_before: dateTo,
                  created_at_after: dateFrom,
                  limit: commentsPerPage,
                  offset: pageNum * commentsPerPage - commentsPerPage
                }
              }
            : {
                ...dataForBack,
                commentsId: commentsPoll?.id,
                data: {
                  ordering: getOrdering(),
                  created_at_before: dateTo,
                  created_at_after: dateFrom,
                  limit: commentsPerPage
                }
              }
        )
        .then((resp) => {
          if (resp.status === 200) {
            setComments(resp.data.results);
            setCommentsLoading(false);
          }
        });
    }
  };

  const sortByDate = () => {
    const createdAt = createTimeOrdering ? 'created_at' : '-created_at';
    setCreateTimeOrdering(!createTimeOrdering);
    setCommentsLoading(true);
    service
      .qrVoteQuestionComments({
        ...dataForBack,
        commentsId: commentsPoll?.id,
        data: {
          ordering: createdAt,
          created_at_before: dateTo,
          created_at_after: dateFrom,
          limit: commentsPerPage
        }
      })
      .then((resp) => {
        if (resp.status === 200) {
          setCount(resp.data.count);
          setCommentsAmount(resp.data.count);
          setComments(resp.data.results);
          setCurrentPage(1);
          setCommentsLoading(false);
        }
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" className="modal-responses">
      <div className="modal-content modal-dialog-scrollable">
        <div className="modal-header">
          <h5>{answersTitle}</h5>
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onHide()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          {answersLoading && <Loading />}
          {!answersLoading && answers.length > 0 && (
            <div className="questions">
              <table className="table">
                <thead>
                  <tr>
                    <th className="questions-polls" scope="col">
                      {`${t('AdditionalAnswersModal.polls')}:`}
                    </th>
                    <th className="questions-quantity text-center" scope="col">
                      {`${t('AdditionalAnswersModal.quantity')}:`}
                    </th>
                    <th className="questions-percentage" scope="col">
                      {`${t('AdditionalAnswersModal.percent')}:`}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortBy(answers, ['position']).map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td className="questions-polls">{item?.title}</td>
                        <td className="questions-quantity text-center">
                          <b>{item?.count_positive_answers}</b>
                        </td>
                        <td className="questions-percentage">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="progress-questions">
                              <div
                                className="progress-questions__item"
                                style={{ width: `${percents[index]}%` }}
                              />
                            </div>
                            {(percents[index] && <b>{percents[index]}%</b>) || (
                              <b>0%</b>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="questions-polls">
                      <b> {`${t('AdditionalAnswersModal.total')}:`}</b>
                    </td>
                    <td className="questions-quantity text-center">
                      <b>{answers[0]?.count_total_answers || 0}</b>
                    </td>
                    <td className="questions-percentage" />
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div className="comments">
            {(!commentsLoading || commentsAmount > 0) && (
              <div className="comments__head">
                <h5 className="comments__title">
                  {`${t('AdditionalAnswersModal.comments')}: ${commentsAmount}`}
                </h5>
                {commentsAmount > 1 && (
                  <a
                    className="sortable-link"
                    role="button"
                    onClick={sortByDate}
                  >
                    {t('AdditionalAnswersModal.sortByDate')}
                    <IconSort className="svg-sprite-icon icon-sort" />
                  </a>
                )}
              </div>
            )}
            {commentsLoading && <Loading />}
            {commentsAmount > 0 && !commentsLoading && (
              <div className="comments__list">
                {!commentsLoading &&
                  comments.map((item) => {
                    return (
                      <div className="comment" key={item?.id}>
                        <div className="comment__head">
                          <span>
                            <span>
                              <IconComment className="svg-sprite-icon icon-comment" />
                            </span>
                            {moment(item?.created_at).format('DD/MM/YYYY')}
                          </span>
                        </div>
                        <div className="comment__text">{item?.body}</div>
                        {/* <button className="comment__collapse-btn" type="button">
                  <IconPlusCircle className="svg-sprite-icon icon-plus-circle" />
                </button> */}
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="comments__pagination">
              <Pagination
                currentPage={currentPage}
                maxPage={maxPage}
                next={next}
                prev={prev}
                jump={jump}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalAnswersModal;

AdditionalAnswersModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  data: PropTypes.shape({})
};

AdditionalAnswersModal.defaultProps = {
  show: false,
  onHide: null,
  data: null
};
