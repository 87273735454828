import React from 'react';
import Polls from '../components/Polls/Polls';
import DefaultInner from './DefaultInner';

const PollsPage = () => {
  return (
    <DefaultInner>
      <Polls />
    </DefaultInner>
  );
};

export default PollsPage;
