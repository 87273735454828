import React, { useEffect, useState, useRef } from 'react';

const Dropdown = ({
  value,
  options,
  placeholder = 'Select',
  onChange,
  text
}) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div
      ref={node}
      className={`page-select ${open && 'is-open'}`}
      onClick={(e) => setOpen(!open)}
    >
      <div className="page-select__selected">
        {value ? `${text} ${value.label}` : placeholder}
      </div>
      {open && (
        <ul className="page-select__list">
          {options.map((opt, index) => (
            <li key={index} onClick={() => handleChange(opt)}>
              {opt.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
