import {
  LOGIN,
  LOGOUT,
  CHANGE_USER_INFO,
  LOADING,
  HELP_DESK
} from '../constants/actionsTypes';

export const initialState = {
  isAuthenticated: false,
  user: JSON.parse(localStorage.getItem('user')) || {},
  token: JSON.parse(localStorage.getItem('token')) || null,
  tokenRefresh: JSON.parse(localStorage.getItem('tokenRefresh')) || null,
  isLoading: false,
  helpDeskModal: false,
  isTestServer:
    process.env.REACT_APP_SERVER === 'https://apitest.liketo.me' ||
    process.env.REACT_APP_SERVER === 'http://localhost:8000'
};

const keysToRemove = ['token', 'tokenRefresh', 'user', 'filters'];

const globalReducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('token', JSON.stringify(action.payload.access));
      localStorage.setItem(
        'tokenRefresh',
        JSON.stringify(action.payload.refresh)
      );
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.access,
        tokenRefresh: action.payload.refresh,
        user: action.payload.user
      };
    case LOGOUT:
      keysToRemove.forEach((k) => localStorage.removeItem(k));
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      };
    case CHANGE_USER_INFO:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case HELP_DESK:
      return {
        ...state,
        helpDeskModal: action.payload
      };
    default:
      return state;
  }
};

export default globalReducer;
