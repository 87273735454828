import React from 'react';
import PropTypes from 'prop-types';

const MainButton = ({ type, className, text, children, disabled, onClick }) => {
  return (
    <button
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
      {children}
    </button>
  );
};

export default MainButton;

MainButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

MainButton.defaultProps = {
  type: 'button',
  className: '',
  text: '',
  children: null,
  disabled: false,
  onClick: null
};
