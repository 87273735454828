import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Map } from 'react-lodash';
import ServiceAPI from '../../services';
import {
  pollName,
  questionName,
  polldevices,
  initialPollDevices,
  pollTouched,
  pollEdit,
  pollsError
} from '../../actions';
import reducer, { initialState } from '../../reducers/changePoll';
import PageContent from '../StyleComponents/PageContent';
import PrevPage from '../Navigation/PrevPage';
import MainButton from '../Buttons/MainButton';
import CancelButton from '../Buttons/CancelButton';
import { ReactComponent as IconConfirm } from '../../img/sprite/svg/confirm.svg';
import { validateNameLength } from '../../usefulFunctions';
import HelpDesk from '../StyleComponents/HelpDesk';
import DefaultInner from '../../Layouts/DefaultInner';

const PollEdit = (props) => {
  const { createNewPoll } = props;
  const service = new ServiceAPI();
  const { t } = useTranslation();
  const { pollId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const notify = () => toast(t('ChangesSaves.changesIsSaved'));

  useEffect(() => {
    service.getDevices().then((resp) => {
      if (resp.status === 200) {
        dispatch(
          initialPollDevices(
            resp.data.results.map((item) => {
              return {
                id: item.id,
                name: item.name,
                address: item.address,
                checked: false
              };
            })
          )
        );
      }
    });
    if (!createNewPoll) {
      service.getSinglePoll(pollId).then((resp) => {
        if (resp.status === 200) {
          dispatch(
            pollEdit({
              pollName: resp.data.title,
              questionName: resp.data.questions[0].body,
              activeDevices: resp.data.devices.map((item) => item.id)
            })
          );
        }
      });
    }
  }, []);

  const validatePollNameField = (value) => {
    dispatch(
      pollsError({
        requiredPollName: !value,
        pollNameLength: !validateNameLength(value, 1, 110)
      })
    );
  };

  const validateQuestionField = (value) => {
    dispatch(
      pollsError({
        requiredReportPoll: !value,
        questionLength: !validateNameLength(value, 1, 52)
      })
    );
  };

  const validateDevices = () => {
    dispatch(
      pollsError({
        requiredDevice: !state.devices.value.filter(
          (item) => item.checked === true
        ).length
      })
    );
  };

  useEffect(() => {
    if (state.devices.touched) validateDevices();
  }, [state.devices.value]);

  const validateFields = () => {
    dispatch(pollTouched());
    validatePollNameField(state.pollName.value);
    validateQuestionField(state.questionName.value);
    validateDevices();
    return (
      !(
        state.pollsErrors.requiredPollName ||
        state.pollsErrors.pollNameLength ||
        state.pollsErrors.requiredQuestion ||
        state.pollsErrors.questionLength ||
        state.pollsErrors.requiredDevice
      ) &&
      state.pollName.value &&
      state.questionName.value
    );
  };

  const handlerSubmit = () => {
    if (!validateFields()) return false;
    const checkedDevices = state.devices.value
      .filter((item) => {
        if (item.checked) return item.id;
      })
      .map((item) => item.id);
    if (createNewPoll) {
      service
        .createPoll({
          title: state.pollName.value,
          question: state.questionName.value,
          devices: checkedDevices
        })
        .then((resp) => {
          if (resp.status === 200) {
            notify();
            history.push('/polls');
          }
        });
    } else {
      service
        .changePoll(pollId, {
          title: state.pollName.value,
          question: state.questionName.value,
          devices: checkedDevices
        })
        .then((resp) => {
          if (resp.status === 200) {
            notify();
            history.push('/polls');
          }
        });
    }
  };

  return (
    <DefaultInner>
      <PageContent>
        <PrevPage pageName={t('createNewPoll.polls')} to="/polls" />
        <div className="content__header">
          <h1 className="content__title">
            {createNewPoll
              ? t('createNewPoll.createNewPoll')
              : t('createNewPoll.editPoll')}
          </h1>
        </div>
        <div className="content__box">
          <div className="page-form">
            <div className="page-form__container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlerSubmit();
                }}
              >
                <div className="form-group pb-4 mb-0 has-label-line">
                  <label htmlFor="poll-name">
                    <span className="required-label">*</span>
                    {t('createNewPoll.pollName')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.pollName.touched &&
                      !state.pollsErrors.requiredPollName &&
                      !state.pollsErrors.pollNameLength
                        ? 'form-control is-valid'
                        : state.pollName.touched &&
                          (state.pollsErrors.requiredPollName ||
                            state.pollsErrors.pollNameLength)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="poll-name"
                    type="text"
                    name="pollName"
                    value={state.pollName.value || ''}
                    onChange={(e) => {
                      dispatch(pollName(e.target.value));
                      validatePollNameField(e.target.value);
                    }}
                  />
                  {state.pollsErrors.requiredPollName && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                  {state.pollsErrors.pollNameLength &&
                    !state.pollsErrors.requiredPollName && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.length110Error')}</span>
                      </div>
                    )}
                  <span className="label-number">1</span>
                </div>
                <div className="form-group pb-4 mb-0 has-label-line">
                  <label htmlFor="poll-question">
                    {t('createNewPoll.question')}
                  </label>
                  <input
                    autoComplete="off"
                    className={
                      state.questionName.touched &&
                      !state.pollsErrors.requiredQuestion &&
                      !state.pollsErrors.questionLength
                        ? 'form-control is-valid'
                        : state.questionName.touched &&
                          (state.pollsErrors.requiredQuestion ||
                            state.pollsErrors.questionLength)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    id="poll-question"
                    type="text"
                    name="question"
                    value={state.questionName.value || ''}
                    onChange={(e) => {
                      dispatch(questionName(e.target.value));
                      validateQuestionField(e.target.value);
                    }}
                    disabled
                  />
                  {state.pollsErrors.requiredQuestion && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                  {state.pollsErrors.questionLength &&
                    !state.pollsErrors.requiredQuestion && (
                      <div className="error">
                        <span>{t('ERROR_TEXT.lengthError')}</span>
                      </div>
                    )}
                  <span className="label-number">2</span>
                  <HelpDesk />
                  {/* <p className="support-help-text">
                  <IconAlert className="svg-sprite-icon icon-alert" />
                  Чтобы изменить обратитесь в <u>техподдержку</u>
                </p> */}
                </div>
                <div className="form-group pb-4 mb-0">
                  <label htmlFor="poll-device">
                    {t('createNewPoll.selectDevice')}
                  </label>
                  <div
                    className="form-group-checkboxes disabled"
                    id="poll-device"
                  >
                    <div className="form-group-checkboxes__container">
                      <Map
                        collection={state.devices.value}
                        iteratee={(item) => {
                          if (item.checked) {
                            return (
                              <div
                                className="custom-control custom-checkbox"
                                key={item.id}
                              >
                                <input
                                  className="custom-control-input"
                                  name={item.id}
                                  type="checkbox"
                                  id={item.id}
                                  checked={item.checked}
                                  onChange={() => {
                                    dispatch(
                                      polldevices(
                                        state.devices.value.map((device) =>
                                          device.id === item.id
                                            ? {
                                                ...device,
                                                checked: !device.checked
                                              }
                                            : device
                                        )
                                      )
                                    );
                                  }}
                                  disabled
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={item.id}
                                >
                                  {`${item.name} ${item.address}`}
                                </label>
                              </div>
                            );
                          }
                        }}
                      />
                    </div>
                    <span className="label-number">3</span>
                  </div>
                  <HelpDesk />
                  {state.pollsErrors.requiredDevice && (
                    <div className="error">
                      <span>{t('ERROR_TEXT.required')}</span>
                    </div>
                  )}
                </div>
                <div className="form-group has-submit-btn mb-0">
                  <MainButton
                    type="submit"
                    className="btn btn-confirm has-icon"
                    text={
                      createNewPoll
                        ? t('createNewPoll.create')
                        : t('createNewPoll.save')
                    }
                  >
                    <span className="icon-btn">
                      <IconConfirm className="svg-sprite-icon icon-confirm" />
                    </span>
                  </MainButton>
                  <CancelButton text={t('createNewPoll.cancel')} to="/polls" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageContent>
    </DefaultInner>
  );
};

export default PollEdit;

PollEdit.propTypes = {
  createNewPoll: PropTypes.bool
};

PollEdit.defaultProps = {
  createNewPoll: false
};
